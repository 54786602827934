import React from 'react'

// Components
import { Title } from '../../components/Title'
import { FeaturedPropertiesLarge } from '../../components/FeaturedPropertiesLarge'
import { BasicInfo } from '../Property/Components/BasicInfo'
import { ListFeatured } from '../Property/Components/ListFeatured'

// Mock
import ImageMock from '../../assets/images/hero-img-desktop.jpg'
import { BASIC_INFO_MOCK } from '../../mocks/basicInfoMock'
import { FEATURED_LIST_MOCK } from '../../mocks/featuredListMock'

const DashboardProperties = () => {
    return (
        <div className='dashboardPage'>
            <Title size='lg'>Propiedades en venta</Title>

            <FeaturedPropertiesLarge
                id={3}
                name='Apartamento Las Lomas 2165'
                location='Envigado'
                price='476.092.000'
                image={ImageMock}
            />

            <BasicInfo details={BASIC_INFO_MOCK} />

            <ListFeatured data={FEATURED_LIST_MOCK} />
        </div>
    )
}

export default DashboardProperties
