import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'
import { RenderMarkDown } from '../../../../components/RenderMarkDown'

// Fields
import {
    FIELDS_BLOG,
    FIELD_KEY_DESCRIPTION,
    FIELD_KEY_IMG_PRIMARY,
    FILED_KEY_TITLE,
} from '../../../../utils/fields'

// Routes
import { BLOG_POST_ROUTER } from '../../../../helpers/constants/routes'

const Allies = ({ list, title, description }) => {
    return (
        <div className='container bg_grey'>
            <div className='content allies'>
                <Title size='lg'>{title}</Title>
                <RenderMarkDown>{description}</RenderMarkDown>

                <div className='allies_list'>
                    {list.map((item) => (
                        <Link
                            to={`${BLOG_POST_ROUTER.path}/${item.id}`}
                            key={item.id}
                            className='allies_list_item'
                        >
                            <img
                                src={
                                    item.attributes[FIELDS_BLOG[FIELD_KEY_IMG_PRIMARY]].data
                                        .attributes.url
                                }
                                alt={item.attributes[FIELDS_BLOG[FILED_KEY_TITLE]]}
                            />
                            <Title size='sm'>
                                {item.attributes[FIELDS_BLOG[FILED_KEY_TITLE]]}
                            </Title>
                            <Text size='sm' color='gray'>
                                <RenderMarkDown>
                                    {item.attributes[FIELDS_BLOG[FIELD_KEY_DESCRIPTION]]}
                                </RenderMarkDown>
                            </Text>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

Allies.propTypes = {
    list: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
}

export default Allies
