import React from 'react'

// Components
import { Title } from '../../components/Title'
import { CardStats } from './Components/CardStats'
import { FeaturedPropertiesLarge } from '../../components/FeaturedPropertiesLarge'
import { RecentActivity } from './Components/RecentActivity'

// Mock
import ImageMock from '../../assets/images/hero-img-desktop.jpg'

const DashboardPage = () => {
    return (
        <div className='dashboardPage'>
            <Title size='lg'>Hola Amanda Lopéz</Title>

            <div className='dashboardPage_content'>
                <div className='dashboardPage_content_col'>
                    <div className='dashboardPage_listStats'>
                        <CardStats title='Propiedades en venta' quantity={1} />
                        <CardStats title='Citas agendadas' quantity={2} />
                        <CardStats title='Perfiles favoritos' quantity={5} />
                    </div>

                    <div className='dashboardPage_featuredProperty'>
                        <Title size='xs'>Propiedad en venta reciente</Title>
                        <FeaturedPropertiesLarge
                            id={3}
                            details={{
                                bed: 3,
                                bathroom: 4,
                                meters: '60.000m2',
                            }}
                            location='Envigado'
                            price='476.092.000'
                            image={ImageMock}
                            shadow
                        />
                    </div>
                </div>
                <div className='dashboardPage_activity'>
                    <RecentActivity />
                </div>
            </div>
        </div>
    )
}

export default DashboardPage
