import React from 'react'

// Components
import { TitleIcon } from '../../../../components/Title'

// Icons
import { FilterIcon } from '../../../../components/Svg'

const FilterTitle = () => {
    return <TitleIcon icon={<FilterIcon />} title='Filtros' />
}

export default FilterTitle
