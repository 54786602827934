import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../../../../components/Text'
import { Input } from '../../../../components/Input'
import { Title } from '../../../../components/Title'
import { Button } from '../../../../components/Button'

const CODEINPUTS = {
    codeOne: '',
    codeTwo: '',
    codeThree: '',
    codeFour: '',
    codeFive: '',
}

const ConfirmCode = ({ handleConfirmCode }) => {
    const [ dataCodes, setDataCodes ] = useState(CODEINPUTS)

    const handleChange = ({ field, value }) => {
        if (!isNaN(value)) {
            setDataCodes({
                ...dataCodes,
                [field]: value.charAt(0),
            })
        }
    }

    const handleConfirm = () => {
    // ! validamos que esten todos los campos
        handleConfirmCode({ code: Object.values(dataCodes).join('') })
    }

    return (
        <div className='confirmCode'>
            <Title center size='sm'>
        Verifica tu cuenta
            </Title>

            <Text>Código de verificación</Text>

            <div className='confirmCode_inputs'>
                {Object.keys(dataCodes).map((code) => (
                    <Input
                        key={code}
                        name={code}
                        value={dataCodes[code]}
                        type='number'
                        min='0'
                        max='9'
                        onChange={(e) =>
                            handleChange({ field: e.target.name, value: e.target.value })
                        }
                    />
                ))}
            </div>

            <Button rounded onClick={handleConfirm}>
        Verificar código
            </Button>
        </div>
    )
}

ConfirmCode.propTypes = {
    handleConfirmCode: PropTypes.func,
}

export default ConfirmCode
