import React from 'react'
import PropTypes from 'prop-types'

// Components
import { RecentActivityItem } from '../RecentActivityItem'

const RecentActivityList = ({ data }) => {
    return (
        <div className='recentActivityList'>
            {data.map((activity) => (
                <RecentActivityItem
                    key={activity.id}
                    image={activity.image}
                    activity={activity.activity}
                    username={activity.username}
                />
            ))}
        </div>
    )
}

RecentActivityList.propTypes = {
    data: PropTypes.array, 
}

export default RecentActivityList
