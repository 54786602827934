export const LIST_LOCATION_SELECT = [
    { value: 'Envigado', label: 'Envigado' },
    { value: 'Sabaneta', label: 'Sabaneta' },
    { value: 'La Estrella', label: 'La Estrella' },
    { value: 'Itagui', label: 'Itagui' },
    { value: 'El Poblado', label: 'El Poblado' },
    { value: 'Laureles', label: 'Laureles' },
    { value: 'Las Palmas', label: 'Las Palmas' },
    { value: 'Rionegro', label: 'Rionegro' },
    { value: 'La Ceja', label: 'La Ceja' },
    { value: 'El Retiro', label: 'El Retiro' },
]

export const LIST_TYPE_PROPERTY_SELECT = [
    { value: 'Apartamento', label: 'Apartamento' },
    { value: 'Apartaestudio', label: 'Apartaestudio' },
    { value: 'ApartaLoft', label: 'ApartaLoft' },
    { value: 'Casa', label: 'Casa' },
    { value: 'Casa Campestre', label: 'Casa Campestre' },
    { value: 'Penthouse', label: 'Penthouse' },
]

export const LIST_ORDER_BY = [
    { value: null, label: 'Ordenar por', isDisabled: true },
    { value: '', label: 'Más reciente' },
    { value: 'asc', label: 'Precio: menor a mayor' },
    { value: 'desc', label: 'Precio: mayor a menor' },
]
