export const FEATURED_LIST_MOCK = [
    'Cocina Integrada',
    'Zona de Ropas',
    'Balcón',
    'Estudio',
    'Cuarto Útil',
    'Parqueadero Paralelo',
    'Conjunto Cerrado',
    'Vestier',
    'Parqueadero de Visitantes',
    'Ascensor',
    'Salón Social',
    'Porteria 24 Hrs',
]
