import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

const RenderMarkDown = ({ children }) => {
    return <ReactMarkdown className='markDown'>{children}</ReactMarkdown>
}

RenderMarkDown.propTypes = {
    children: PropTypes.node,
}

export default RenderMarkDown
