import {
    FIELD_KEY_ADMINISTRATION,
    FIELD_KEY_BATHROOMS,
    FIELD_KEY_TOTAL_AREA,
    FIELD_KEY_BUILT_AREA,
    FIELD_KEY_LOCATION,
    FIELD_KEY_ANTIQUITY,
    FIELD_KEY_CONTANT_PHONE,
    FIELD_KEY_PROPERTY_CODE,
    FIELDS_PROPERTY,
} from './fields'

export const TRANS_FIELDS = {
    [FIELDS_PROPERTY[FIELD_KEY_BATHROOMS]]: 'Baños',
    [FIELDS_PROPERTY[FIELD_KEY_TOTAL_AREA]]: 'Área Total',
    [FIELDS_PROPERTY[FIELD_KEY_BUILT_AREA]]: 'Área Construida',
    [FIELDS_PROPERTY[FIELD_KEY_ANTIQUITY]]: 'Antigüedad',
    [FIELDS_PROPERTY[FIELD_KEY_LOCATION]]: 'Ubicación',
    [FIELDS_PROPERTY[FIELD_KEY_CONTANT_PHONE]]: 'Teléfono de Contacto',
    [FIELDS_PROPERTY[FIELD_KEY_ADMINISTRATION]]: 'Administración',
    [FIELDS_PROPERTY[FIELD_KEY_PROPERTY_CODE]]: 'Código Propiedad',
}
