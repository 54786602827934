import React, { useEffect, useState } from 'react'

// Components
import { HeroImg } from './components/HeroImg'
import { Allies } from './components/Allies'
import { PlacesAndSectors } from './components/PlacesAndSectors'
import { SocialMedia } from './components/SocialMedia'
import { FeaturedProperties } from '../../components/FeaturedProperties'
import {
    LoadingCardProperty,
    LoadingContainer,
    LoadingHome,
} from '../../components/Loading'

// Hooks
import {
    useProperties,
    usePlacesOfInterest,
    useBlog,
    useHome,
} from '../../hooks'

// Fields
import { FIELDS_HOME } from '../../utils/fields'

// Helpers
import { scrollTop } from '../../helpers/utils'

const Home = () => {
    const [ loadingPage, setLoadingPage ] = useState(true)
    const { listProperties, getFeaturedProperties } = useProperties()
    const { listPlacesOfPlaces, getPlacesOfInterest } = usePlacesOfInterest()
    const { listBlog, getBlogsOutstanding } = useBlog()
    const { dataHome, getDataHome } = useHome()

    useEffect(() => {
        (async () => {
            try {
                scrollTop()
                await getFeaturedProperties()
                await getPlacesOfInterest({ limit: 4 })
                await getBlogsOutstanding()
                await getDataHome()
            } catch (error) {
                console.error({ error })
            } finally {
                setLoadingPage(false)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loadingPage)
        return (
            <>
                <LoadingHome />
                <LoadingContainer col={3}>
                    <LoadingCardProperty count={9} />
                </LoadingContainer>
            </>
        )

    return (
        <div className='home'>
            <HeroImg
                header={dataHome[FIELDS_HOME.header]}
                image={dataHome[FIELDS_HOME.header_img]?.data?.attributes?.url}
            />
            {listProperties &&
                listProperties.data &&
                listProperties.data.length > 0 && (
                <FeaturedProperties
                    title={dataHome[FIELDS_HOME.title_property]}
                    description={dataHome[FIELDS_HOME.description_property]}
                    listProperty={listProperties.data}
                />
            )}
            {listBlog && listBlog.data.length > 0 && (
                <Allies
                    list={listBlog.data}
                    title={dataHome[FIELDS_HOME.title_blog]}
                    description={dataHome[FIELDS_HOME.description_blog]}
                />
            )}
            {listPlacesOfPlaces && listPlacesOfPlaces.data.length > 0 && (
                <PlacesAndSectors
                    data={listPlacesOfPlaces.data}
                    title={dataHome[FIELDS_HOME.title_places_of_interest]}
                    description={dataHome[FIELDS_HOME.description_places_of_interest]}
                />
            )}
            <SocialMedia
                title={dataHome[FIELDS_HOME.title_social_media]}
                description={dataHome[FIELDS_HOME.description_social_media]}
                image={dataHome[FIELDS_HOME.social_media_img].data.attributes.url}
            />
        </div>
    )
}

export default Home
