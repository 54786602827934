import React, { useState } from 'react'

// Components
import Logo from '../../../components/Logo/Logo'
import { Title } from '../../../components/Title'
import { Text } from '../../../components/Text'
import {
    Button,
    ButtonFacebook,
    ButtonGoogle,
} from '../../../components/Button'
import { Input, CheckBox } from '../../../components/Input'
import ModalRegister from '../components/ModalRegister/ModalRegister'
import { ModalLogin } from '../../../components/Modal'
import { ModalTypeUser } from '../../../components/Modal'

// Cognito
import { signIn, signUp } from '../../../service/AwsCognitoConector'

// Hook
import { useOpenAndCloseHook } from '../../../hooks'

// Images
import RegisterImg from '../../../assets/images/register.jpg'

// Icons
import { ArrowRightIcon } from '../../../components/Svg'

// Helpers
import { validateEmail } from '../../../utils/helpers'

// Const
import { USER_BUY } from '../../../const/typeUser'

// Field
import {
    FIELD_KEY_EMAIL,
    FIELD_KEY_PHONE,
    FIELD_KEY_PRIVACITY,
} from '../../../utils/fields'

const Register = () => {
    const [ modalRegister, setModalRegister ] = useOpenAndCloseHook()
    const [ modalLogin, setModalLogin ] = useOpenAndCloseHook()

    const [ email, setEmail ] = useState('')
    const [ emailError, setEmailError ] = useState('')
    const [ phone_number, setPhoneNumber ] = useState('')
    const [ privacity, setPrivacity ] = useState('')
    const [ privacityError, setPrivacityError ] = useState('')
    const [ modalTypeUser, setModalTypeUser ] = useState(false)
    const [ typeUser, setTypeUser ] = useState(USER_BUY)

    const handleChangeRegisterData = ({ field, value }) => {
    // ! validacion de los inputs
        if (field === FIELD_KEY_EMAIL) {
            if (emailError) setEmailError('')

            return setEmail(value)
        }

        if (field === FIELD_KEY_PHONE) {
            return setPhoneNumber(value)
        }

        if (field === FIELD_KEY_PRIVACITY) {
            if (privacityError) setPrivacityError('')

            return setPrivacity(value)
        }
    }

    const handleRegister = async ({ data }) => {
    // if (!email) return;

        console.log({ data, email, phone_number })
        try {
            await signUp({ email, name: 'test', phone_number })
        } catch (e) {
            // User already exists
            console.log('existing user')
        }
        // triggers otp flow
        await signIn({ email })
    }

    const handleOpenModalTypeUser = () => {
        if (!privacity)
            return setPrivacityError('Debe aceptar los terminos y condiciones')

        setModalTypeUser(true)
    }

    const handleOpenModalRegister = () => {
    // if (email) {
    //   if (!validateEmail({ email }))
    //     return setEmailError('El email no es correcto');

        setModalRegister(!modalRegister)
    // }
    }

    const handleSelectTypeUser = ({ type }) => {
        setTypeUser(type)
    }

    return (
        <>
            <div className='registerPage'>
                <div className='registerPage_img'>
                    <img src={RegisterImg} alt='register' />
                    <Title color='white' customClass='registerPage_img_text' size='lg'>
                        Encuentra tu lugar!
                    </Title>
                </div>

                <div className='registerPage_content'>
                    <div className='registerPage_content_header'>
                        <Logo color='white' />

                        <Button
                            color='white'
                            transparent
                            icon
                            underline
                            onClick={() => setModalLogin(true)}
                        >
                            Iniciar sesión <ArrowRightIcon color='white' />
                        </Button>
                    </div>

                    <div className='registerPage_content_body'>
                        <Title size='md'>
                            Compra y vende tu casa con un agente de confianza
                        </Title>
                        <Text color='gray'>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                            Voluptas, tempora.
                        </Text>

                        <div className='registerPage_content_btnSocial'>
                            <ButtonFacebook />
                            <ButtonGoogle />
                        </div>

                        <Title size='md' center>
                            O registrate
                        </Title>

                        <div className='registerPage_content_form'>
                            <Input
                                label='Email'
                                type='email'
                                name={FIELD_KEY_EMAIL}
                                placeholder='Email'
                                value={email}
                                error={emailError}
                                onChange={(e) =>
                                    handleChangeRegisterData({
                                        field: e.target.name,
                                        value: e.target.value,
                                    })
                                }
                            />
                            <Input
                                label='Teléfono'
                                name={FIELD_KEY_PHONE}
                                placeholder='Teléfono'
                                value={phone_number}
                                onChange={(e) =>
                                    handleChangeRegisterData({
                                        field: e.target.name,
                                        value: e.target.value,
                                    })
                                }
                            />
                            <CheckBox
                                name={FIELD_KEY_PRIVACITY}
                                value={privacity}
                                error={privacityError}
                                onChange={(e) =>
                                    handleChangeRegisterData({
                                        field: e.target.name,
                                        value: e.target.checked,
                                    })
                                }
                                label='Acepto los terminos y condiciones'
                            />
                            <Button alignSelfStart rounded onClick={handleOpenModalTypeUser}>
                                Registrarme
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modals */}
            {modalRegister && (
                <ModalRegister close={setModalRegister} onRegister={handleRegister} />
            )}

            {modalLogin && <ModalLogin close={setModalLogin} />}

            {modalTypeUser && (
                <ModalTypeUser
                    selectType={typeUser}
                    onConfirm={handleOpenModalRegister}
                    onSelectType={handleSelectTypeUser}
                    onClose={() => setModalTypeUser(false)}
                />
            )}
        </>
    )
}

export default Register
