import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Modal } from '../../../../components/Modal'
import { Filter, FilterTitle } from './'
import { InputsFilter } from '../InputsFilter'

const FilterMobile = ({ close, data, onChangeFilter, filterFeatureList }) => {
    return (
        <Modal onClose={close} direction='right' className='filterModal'>
            <div className='filterMobile_padding'>
                <FilterTitle />
            </div>
            <div className='filterMobile_content filterMobile_padding'>
                <InputsFilter data={data} onChangeFilter={onChangeFilter} />
                <Filter
                    tablet
                    data={data}
                    onChangeFilter={onChangeFilter}
                    filterFeatureList={filterFeatureList}
                />
            </div>
        </Modal>
    )
}

FilterMobile.propTypes = {
    data: PropTypes.object,
    close: PropTypes.func,
    onChangeFilter: PropTypes.func,
    filterFeatureList: PropTypes.array, 
}

export default FilterMobile
