import * as React from 'react'

const GroupIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95 95'>
        <defs>
            <style>{'.cls-3{fill:#fff}'}</style>
        </defs>
        <g>
            <rect
                width={95}
                height={95}
                rx={11.4}
                style={{
                    fill: '#062828',
                }}
            />
            <g>
                <g>
                    <g>
                        <path
                            style={{
                                fill: 'none',
                            }}
                            d='M17.11 17.11h60.8v60.8h-60.8z'
                        />
                        <path
                            className='cls-3'
                            d='M47.51 31.11a6.09 6.09 0 0 0-3.44 1 6 6 0 0 0-2.28 2.8 6.13 6.13 0 0 0-.36 3.59 6.28 6.28 0 0 0 1.71 3.18 6.21 6.21 0 1 0 4.37-10.57Zm0 10.09a3.92 3.92 0 0 1-2.13-.65 3.87 3.87 0 0 1 1.41-7 4.13 4.13 0 0 1 2.23.23 3.64 3.64 0 0 1 1.71 1.44 3.6 3.6 0 0 1 .68 2.13 4 4 0 0 1-1.16 2.72 3.9 3.9 0 0 1-2.74 1.13Z'
                        />
                        <path
                            className='cls-3'
                            d='M74.68 60.34a12.49 12.49 0 0 0-2.12-4.85 12.27 12.27 0 0 0-14.25-4.21 15.7 15.7 0 0 0-21.46 0 12.31 12.31 0 0 0-14.32 4.21 12.1 12.1 0 0 0-2.21 4.85 3 3 0 0 0 0 1.26 3.26 3.26 0 0 0 .57 1.13 3.36 3.36 0 0 0 1.05.82 3.3 3.3 0 0 0 1.31.3H71.7a2.93 2.93 0 0 0 1.31-.3 3.4 3.4 0 0 0 1.06-.82 2.92 2.92 0 0 0 .59-1.13 3.14 3.14 0 0 0 0-1.26Zm-51.32 1.23a.82.82 0 0 1-.57-.26.64.64 0 0 1 0-.43A9.93 9.93 0 0 1 32.36 53a11.17 11.17 0 0 1 2.77.33 15.24 15.24 0 0 0-3 6.52 3.31 3.31 0 0 0 0 1.82Zm37-.41a1.39 1.39 0 0 1-.49.34 1.25 1.25 0 0 1-.54.12H35.69a1.35 1.35 0 0 1-.57-.12 1.85 1.85 0 0 1-.44-.34 1.42 1.42 0 0 1-.23-.44.9.9 0 0 1 0-.46 13.21 13.21 0 0 1 2.79-6 12.57 12.57 0 0 1 1.83-1.59 13.31 13.31 0 0 1 16.91 0 11 11 0 0 1 1.74 1.67 13.05 13.05 0 0 1 2.93 6 .67.67 0 0 1-.31.82Zm11.86.21a.88.88 0 0 1-.59.25h-8.7A3.41 3.41 0 0 0 63 59.8a15.31 15.31 0 0 0-3-6.52 10.15 10.15 0 0 1 2.57-.36 10 10 0 0 1 9.75 8 .45.45 0 0 1 0 .23.31.31 0 0 1-.15.23Z'
                        />
                        <path
                            className='cls-3'
                            d='M62.62 38.32a4.93 4.93 0 0 0-5 4.85 5.14 5.14 0 0 0 .13 1.18 5.4 5.4 0 0 0 1.39 2.57 5.14 5.14 0 0 0 2.57 1.36 5 5 0 1 0 .89-10Zm0 7.7a2.71 2.71 0 0 1-1.46-.46 2.58 2.58 0 0 1-1-1.21 2.88 2.88 0 0 1-.16-1.54 2.58 2.58 0 0 1 .75-1.38 2.61 2.61 0 0 1 1.36-.72 2.59 2.59 0 0 1 1.53.15 2.9 2.9 0 0 1 1.21 1 2.6 2.6 0 0 1-2.26 4.05Z'
                        />
                        <path
                            className='cls-3'
                            d='M32.39 38.32a5 5 0 0 0-2.85.87 5.15 5.15 0 0 0-1.85 2.28 4.92 4.92 0 0 0-.24 2.91A4.81 4.81 0 0 0 28.83 47a5 5 0 0 0 2.57 1.36 4.85 4.85 0 0 0 2.89-.36A5 5 0 0 0 37 41.53v-.1a4.39 4.39 0 0 0-2.65-2.7 5.2 5.2 0 0 0-1.96-.41Zm0 7.7a2.74 2.74 0 0 1-1.46-.49 2.5 2.5 0 0 1-1-1.2 2.88 2.88 0 0 1 0-1.54 2.49 2.49 0 0 1 .75-1.36 2.71 2.71 0 0 1 1.38-.72 2.54 2.54 0 0 1 1.54.15 2.81 2.81 0 0 1 1.05 1 2.84 2.84 0 0 1 .46 1.49 2.81 2.81 0 0 1-.23 1 2.85 2.85 0 0 1-.59.87 2.91 2.91 0 0 1-.87.57 3.22 3.22 0 0 1-1.06.21Z'
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default GroupIcon
