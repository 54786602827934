import * as React from 'react'
import PropTypes from 'prop-types'

const InfoIcon = ({ color = '#062828' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95 95'>
        <defs>
            <style>{'.cls-3{fill:#fff}'}</style>
        </defs>
        <g>
            <rect
                width={95}
                height={95}
                rx={11.4}
                style={{
                    fill: { color },
                }}
            />
            <g>
                <g>
                    <g>
                        <path
                            style={{
                                fill: 'none',
                            }}
                            d='M17.11 17.11h60.8v60.8h-60.8z'
                        />
                        <path
                            className='cls-3'
                            d='M51.36 42.5H35.19a1 1 0 0 1-.8-.32 1.14 1.14 0 0 1-.35-.83 1.2 1.2 0 0 1 1.15-1.15h16.17a1.14 1.14 0 0 1 1.15 1.15 1.1 1.1 0 0 1-1 1.15Z'
                        />
                        <path
                            className='cls-3'
                            d='M51.36 49.69H35.19a1.1 1.1 0 0 1-.8-.35 1.13 1.13 0 0 1 0-1.59 1.06 1.06 0 0 1 .8-.35h16.17a1.14 1.14 0 0 1 .83.35 1.19 1.19 0 0 1 0 1.63 1.16 1.16 0 0 1-.83.31Z'
                        />
                        <path
                            className='cls-3'
                            d='M51.36 35.32H35.19a1.08 1.08 0 0 1-.81-.33 1.16 1.16 0 0 1-.35-.83A1.21 1.21 0 0 1 35.18 33h16.17a1.15 1.15 0 0 1 .83.35 1.06 1.06 0 0 1 .33.8 1.16 1.16 0 0 1-.33.83 1.12 1.12 0 0 1-.83.33Z'
                        />
                        <path
                            className='cls-3'
                            d='M62.23 51.56V28.83a5.78 5.78 0 0 0-1.68-4.08 5.84 5.84 0 0 0-4.1-1.75H31a5.9 5.9 0 0 0-4 1.75 5.84 5.84 0 0 0-1.71 4.08v32.35A5.81 5.81 0 0 0 31 67h19.46A10.37 10.37 0 0 0 54 70.49a10.11 10.11 0 0 0 4.78 1.45 10.3 10.3 0 0 0 5-.92 10.84 10.84 0 0 0 3.73-3.13 10.44 10.44 0 0 0-5.39-16.33Zm-12.82 13.1H31a3.46 3.46 0 0 1-3.55-3.35V28.83a3.44 3.44 0 0 1 3.4-3.46h25.6A3.45 3.45 0 0 1 60 28.7a.57.57 0 0 1 0 .13v22.36h-.48a10 10 0 0 0-4.68 1.13 10.35 10.35 0 0 0-5.56 7.53 10.53 10.53 0 0 0 .3 4.83Zm10 5a8.43 8.43 0 0 1-3.28-.7 8 8 0 0 1-2.7-2A8.2 8.2 0 0 1 52 64.68a8.1 8.1 0 0 1 7.48-11.19H60a8.19 8.19 0 0 1 6.53 4 8 8 0 0 1 1 5.51 8.22 8.22 0 0 1-2.8 4.75 8 8 0 0 1-5.29 1.85Z'
                        />
                        <path
                            className='cls-3'
                            d='M59.33 64.74a2.2 2.2 0 0 1-.88-.18 2.57 2.57 0 0 1-.76-.5l-2-2.08a1.14 1.14 0 1 1 1.41-1.78 1.07 1.07 0 0 1 .19.18l2 2 4.41-4.36a1.2 1.2 0 0 1 .82-.33 1.14 1.14 0 0 1 1.15 1.16 1.09 1.09 0 0 1-.34.82L61 64.06a2.75 2.75 0 0 1-.77.5 2.33 2.33 0 0 1-.9.18Z'
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

InfoIcon.propTypes = {
    color: PropTypes.string,
}

export default InfoIcon
