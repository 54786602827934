import * as React from 'react'

const KeyIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95 95'>
        <defs>
            <style>{'.cls-3{fill:#ececec}'}</style>
        </defs>
        <g>
            <rect
                width={95}
                height={95}
                rx={11.4}
                style={{
                    fill: '#062828',
                }}
            />
            <g>
                <g>
                    <g>
                        <path
                            style={{
                                fill: 'none',
                            }}
                            d='M17.11 17.11h60.8v60.8h-60.8z'
                        />
                        <path
                            className='cls-3'
                            d='M56.07 48.57a10.64 10.64 0 1 1 7.6-3.11 10.64 10.64 0 0 1-7.6 3.11Zm0-18.74a8.07 8.07 0 0 0-8.11 8v.06a8 8 0 0 0 2.38 5.73 8.11 8.11 0 1 0 5.73-13.88Z'
                        />
                        <path
                            className='cls-3'
                            d='m58.27 37.1-1.36-1.35a1.18 1.18 0 0 0-1.65 0l-1.37 1.35a1.16 1.16 0 0 0 0 1.64l1.37 1.37a1.16 1.16 0 0 0 1.65 0l1.36-1.37a1.15 1.15 0 0 0 0-1.64Z'
                        />
                        <path
                            className='cls-3'
                            d='M43.45 65.83a1 1 0 0 1-.81.32 1.15 1.15 0 0 1-.83-.32l-6-6-2.89 2.86a1 1 0 0 1-.81.35 1.11 1.11 0 0 1-.84-.35 1.21 1.21 0 0 1-.27-.79 1.24 1.24 0 0 1 .32-.84l17.1-17.12a1.17 1.17 0 0 1 1.65 0 1.19 1.19 0 0 1 .35.84 1.21 1.21 0 0 1-.33.83l-8.92 8.9 6 6a1.19 1.19 0 0 1-1.67 1.68l-6-6-2.16 2.05 6 6a.84.84 0 0 1 .26.38 1 1 0 0 1 0 .43 1.19 1.19 0 0 1 0 .46.84.84 0 0 1-.15.32Z'
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default KeyIcon
