import * as React from 'react'
import PropTypes from 'prop-types'

const FilterIcon = ({ color = '#062828' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85 85'>
        <defs>
            <style>{'.cls-3{fill:#fff}'}</style>
        </defs>
        <g>
            <rect
                width={85}
                height={85}
                rx={12.75}
                style={{
                    fill: color,
                }}
            />
            <g>
                <g>
                    <g>
                        <path
                            style={{
                                fill: 'none',
                            }}
                            d='M14.88 14.88h55.25v55.25H14.88z'
                        />
                        <path
                            className='cls-3'
                            d='M61.13 32.28H40.26a4.47 4.47 0 0 0-1.5-2.53 4.32 4.32 0 0 0-5.56 0 4.49 4.49 0 0 0-1.49 2.53h-7.83a1 1 0 0 0-.72.32 1 1 0 0 0 0 1.42 1.08 1.08 0 0 0 .72.31h7.75a4.51 4.51 0 0 0 1.52 2.49 4.37 4.37 0 0 0 5.52 0 4.44 4.44 0 0 0 1.59-2.49h20.93a1.07 1.07 0 0 0 1-1 1 1 0 0 0-.31-.74 1 1 0 0 0-.78-.31ZM38 34.37a2.76 2.76 0 0 1-.94.89 2.22 2.22 0 0 1-2.38 0 2.44 2.44 0 0 1-.83-.89 2.12 2.12 0 0 1-.33-1 2 2 0 0 1 .27-1.07 2.35 2.35 0 0 1 3.34-1 2.36 2.36 0 0 1 .85.92 2.17 2.17 0 0 1 .27 1.06 2.12 2.12 0 0 1-.25 1.09Z'
                        />
                        <path
                            className='cls-3'
                            d='M61.13 41.56h-3.52a4.56 4.56 0 0 0-1.5-2.51 4.31 4.31 0 0 0-2.77-1 4.25 4.25 0 0 0-2.76 1 4.18 4.18 0 0 0-1.5 2.51h-25.2a1 1 0 1 0 0 2.06h25.35a4.18 4.18 0 0 0 1.59 2.24 4.32 4.32 0 0 0 2.55.83 4.53 4.53 0 0 0 2.63-.92 4.24 4.24 0 0 0 1.56-2.24h3.62a1 1 0 0 0 1-.94v-.09a1 1 0 0 0-.27-.69 1.06 1.06 0 0 0-.78-.25Zm-5.87 2.06a2.34 2.34 0 0 1-1.92 1.08 2.43 2.43 0 0 1-1.09-.29 2.14 2.14 0 0 1-.79-.79 2.11 2.11 0 0 1-.2-2.06 2.05 2.05 0 0 1 .83-1 2.2 2.2 0 0 1 2.46 0 2.42 2.42 0 0 1 .83 1 2.33 2.33 0 0 1 .18.83 2.21 2.21 0 0 1-.31 1.23Z'
                        />
                        <path
                            className='cls-3'
                            d='M61.13 50.91H37a4.54 4.54 0 0 0-1.52-2.42 4.34 4.34 0 0 0-5.41 0 4.54 4.54 0 0 0-1.52 2.42H23.9a1.07 1.07 0 0 0-1 1 1 1 0 0 0 .31.74 1.06 1.06 0 0 0 .72.29h4.67a4.34 4.34 0 0 0 5.27 3.16 4.09 4.09 0 0 0 1.62-.79A4.16 4.16 0 0 0 37 53h24.13a.9.9 0 0 0 .72-.29 1 1 0 0 0 .31-.74 1.08 1.08 0 0 0-1-1Zm-28.36 3.31a2.45 2.45 0 0 1-1.21-.31 2.27 2.27 0 0 1-.85-.94 2 2 0 0 1 0-2.06 2.21 2.21 0 0 1 .85-.94 2.06 2.06 0 0 1 1.21-.32A2.24 2.24 0 0 1 34 50a2 2 0 0 1 .85.92 1.77 1.77 0 0 1 .29 1 1.81 1.81 0 0 1-.29 1 2.21 2.21 0 0 1-.85.89 2.58 2.58 0 0 1-1.23.41Z'
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

FilterIcon.propTypes = {
    color: PropTypes.string,
}

export default FilterIcon
