import * as React from 'react'
import PropTypes from 'prop-types'

const LocationIcon = ({ color = '#062828' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
        <g>
            <path fill='none' d='M0 0h20v20H0z' />
            <path
                d='M9.997 17.308a1.15 1.15 0 0 1-.592-.183 1.092 1.092 0 0 1-.408-.463l-3.083-6.15a9.678 9.678 0 0 1-.3-.625 7.05 7.05 0 0 1-.567-1.558 5.133 5.133 0 0 1 .192-2.133 5 5 0 0 1 1.069-1.863 4.867 4.867 0 0 1 1.908-1.308 4.958 4.958 0 0 1 4.492.467 5.125 5.125 0 0 1 1.6 1.667 4.767 4.767 0 0 1 .625 3.175 8.917 8.917 0 0 1-.55 1.667c-.108.242-.2.433-.267.558l-3.083 6.103a1.075 1.075 0 0 1-.433.45 1.183 1.183 0 0 1-.603.196Zm0-13.846a4.108 4.108 0 0 0-2.117.583 3.742 3.742 0 0 0-1 .833 4.258 4.258 0 0 0-.892 1.542 4.167 4.167 0 0 0-.158 1.808 5.533 5.533 0 0 0 .492 1.375c.108.25.208.442.283.583l3.058 6.15a.333.333 0 0 0 .342.208.383.383 0 0 0 .208-.05.508.508 0 0 0 .142-.158l3.075-6.158c.075-.133.158-.308.258-.525a8.45 8.45 0 0 0 .52-1.491 4.058 4.058 0 0 0-.542-2.617 4.242 4.242 0 0 0-2.575-1.953 4.467 4.467 0 0 0-1.094-.13Z'
                fill={color}
            />
            <path
                d='M10 10.1a1.933 1.933 0 0 1-1.067-.325 1.875 1.875 0 0 1-.708-.833 1.9 1.9 0 0 1-.108-1.117 1.808 1.808 0 0 1 .525-.983A1.925 1.925 0 0 1 11.925 8.2a1.967 1.967 0 0 1-.567 1.358A1.933 1.933 0 0 1 10 10.1Zm0-3.075a1.158 1.158 0 0 0-.642.192 1.117 1.117 0 0 0-.425.517 1.15 1.15 0 0 0 1.508 1.51 1.117 1.117 0 0 0 .517-.425A1.137 1.137 0 0 0 10 7.027Z'
                fill={color}
            />
        </g>
    </svg>
)

LocationIcon.propTypes = {
    color: PropTypes.string,
}

export default LocationIcon
