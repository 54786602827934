import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'
import { PlacesAndSectorsList } from '../../../../components/PlacesAndSectorsList'

const PlacesOfInterest = ({ listPlaces }) => {
    return (
        <div className='placesOfInterest'>
            <Title size='md'>Sitios de Interés</Title>
            <Text>
        Sé el primero en conocer estas propiedades antes que muchas personas.
            </Text>
            <PlacesAndSectorsList data={listPlaces} />
        </div>
    )
}

PlacesOfInterest.propTypes = {
    listPlaces: PropTypes.array, 
}

export default PlacesOfInterest
