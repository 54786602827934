import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'

// Utils
import { formatMoney } from '../../utils/helpers'

const FeaturedPropertiesPrice = ({ price, size = 'xl' }) => {
    return (
        <div className='featuredPropertiesPrice'>
            <Text size={size} color='blue_green'>
                {`$ ${formatMoney({ number: price })}`}
            </Text>
        </div>
    )
}

FeaturedPropertiesPrice.propTypes = {
    price: PropTypes.string,
    size: PropTypes.string,
}

export default FeaturedPropertiesPrice
