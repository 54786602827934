import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'

const CardStats = ({ title, quantity }) => {
    return (
        <div className='cardStats'>
            <Text size='sm' bold>
                {title}
            </Text>
            <Title size='lg' color='blue_green'>
                {quantity}
            </Title>
        </div>
    )
}

CardStats.propTypes = {
    title: PropTypes.string,
    quantity: PropTypes.string,
}

export default CardStats
