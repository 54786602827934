export const BASIC_INFO_MOCK = [
    { label: 'Tipo', value: 'Casa privada' },
    { label: 'Habitaciones', value: 5 },
    { label: 'Baños', value: 5 },
    { label: 'Área Total', value: '105 m2' },
    { label: 'Área Construida', value: '105 m2' },
    { label: 'Estrato', value: 6 },
    { label: 'Parqueaderos', value: 2 },
    { label: 'Niveles', value: 1 },
    { label: 'Antigüedad', value: '10 - 20 Años' },
]
