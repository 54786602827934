import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Input, InputGroupRange } from '../../../../components/Input'
import { SelectCustom } from '../../../../components/SelectCustom'
import { LocationIcon, TypePropertieIcon } from '../../../../components/Svg'

// Utils
import {
    FIELD_KEY_LOCATION,
    FIELD_KEY_TYPE,
    FIELD_KEY_PRICE,
} from '../../../../utils/fields'

// Const
import {
    LIST_TYPE_PROPERTY_SELECT,
    LIST_LOCATION_SELECT,
} from '../../../../const/selectValues'
import { MIN_PRICE, MAX_PRICE } from '../../../../const/inputRange'

const InputsFilter = ({ data, onChangeFilter }) => {
    const handleChangeFilter = ({ value, field }) => {
        onChangeFilter({ field, value })
    }

    return (
        <div className='inputsFilter_properties'>
            <Input label='Ubicación' labelBold>
                <SelectCustom
                    onChange={(e) =>
                        handleChangeFilter({ field: FIELD_KEY_LOCATION, value: e })
                    }
                    placeholder='Ubicación'
                    listOptions={LIST_LOCATION_SELECT}
                    className='hero_img_select zIndex-6'
                    icon={<LocationIcon />}
                    isMulti
                    defaultValue={data[FIELD_KEY_LOCATION]}
                />
            </Input>

            <Input label='Tipo de propiedad' labelBold>
                <SelectCustom
                    onChange={(e) =>
                        handleChangeFilter({ field: FIELD_KEY_TYPE, value: e })
                    }
                    placeholder='Tipo de propiedad'
                    listOptions={LIST_TYPE_PROPERTY_SELECT}
                    className='hero_img_select zIndex-5'
                    icon={<TypePropertieIcon />}
                    isMulti
                    defaultValue={data[FIELD_KEY_TYPE]}
                />
            </Input>

            <InputGroupRange
                label='Presupuesto'
                field={FIELD_KEY_PRICE}
                type={FIELD_KEY_PRICE}
                min={MIN_PRICE}
                max={MAX_PRICE}
                data={data}
                onChange={handleChangeFilter}
            />
        </div>
    )
}

InputsFilter.propTypes = {
    data: PropTypes.object,
    onChangeFilter: PropTypes.func, 
}

export default InputsFilter
