const BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`

export async function API_PUBLIC({ endpoint, method = 'GET', body }) {
    const config = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
    }
    if (body) {
        Object.assign(config, { body: JSON.stringify(body) })
    }

    const send = await fetch(BASE_URL + endpoint, config)

    return send.json()
}
