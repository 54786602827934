import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Routes
import { HOME_ROUTER } from '../../helpers/constants/routes'

// IMAGES
import LogoBlack from '../../assets/images/logoBlack.svg'
import LogoWhite from '../../assets/images/logoWhite.svg'

const Logo = ({ size = 'sm', color = 'black' }) => {
    const sizeLogo = {
        md: '280px',
        sm: '200px',
    }

    const colorLogo = {
        black: LogoBlack,
        white: LogoWhite,
    }
    return (
        <Link
            to={HOME_ROUTER.path}
            style={{
                display: 'flex',
                alignSelf: 'center',
            }}
        >
            <img
                style={{
                    width: sizeLogo[size],
                }}
                src={colorLogo[color]}
                alt='Logo'
            />
        </Link>
    )
}

Logo.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
}

export default Logo
