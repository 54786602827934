import React from 'react'

// Components
import { Text } from '../../../../components/Text'
import { RecentActivityList } from './Components/RecentActivityList/'

// Mock
import { RECENT_ACTIVITY_MOCKS } from '../../../../mocks/recentActivity'

const RecentActivity = () => {
    return (
        <div className='recentActivity'>
            <Text size='xs' bold>
                Activadad reciente
            </Text>

            <RecentActivityList data={RECENT_ACTIVITY_MOCKS} />
        </div>
    )
}

export default RecentActivity
