import * as React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

// Context
import { UserContextProvider } from './Context/UserContext'

// Components
import { Layout } from './components/Layout'

// Pages
import { Register } from './pages/Auth/Register'
import { Home } from './pages/Home'
import { Properties } from './pages/Properties'
import { AboutUs } from './pages/AboutUs'
import { Blog } from './pages/Blog'
import { Property } from './pages/Property'
import BlogPost from './pages/BlogPost/BlogPost'
import {
    DashboardPage,
    DashboardLayout,
    DashboardProperties,
} from './pages/Dashboard'
import DashboardFavorite from './pages/Dashboard/DashboardFavorite'
import { PlacesOfInterest } from './pages/PlacesOfInterest'
import { PrivacyPolicy } from './pages/Policy/PrivacyPolicy'
import { TermsAndConditions } from './pages/Policy/TermsAndConditions'

// Helpers
import {
    HOME_ROUTER,
    PROPERTIES_ROUTER,
    SELLS_ROUTER,
    ABOUTUS_ROUTER,
    BLOG_ROUTER,
    REGISTER_ROUTER,
    PROPERTY_ROUTER,
    BLOG_POST_ROUTER,
    DASHBOARD_ROUTER,
    DASHBOARD_PROPERTIES_ROUTER,
    DASHBOARD_FAVORITE_ROUTER,
    PLACES_OF_INTEREST_ROUTER,
    PRIVACY_POLICY_ROUTER,
    TERMS_AND_CONDITIONS_ROUTER,
} from './helpers/constants/routes'

const App = () => (
    <UserContextProvider>
        <BrowserRouter>
            <Routes>
                <Route path={HOME_ROUTER.path} element={<Layout />}>
                    <Route path={HOME_ROUTER.path} element={<Home />} />
                    <Route path={ABOUTUS_ROUTER.path} element={<AboutUs />} />

                    {/* Blog */}
                    <Route path={BLOG_ROUTER.path} element={<Blog />} />
                    <Route path={`${BLOG_POST_ROUTER.path}/:id`} element={<BlogPost />} />

                    {/* Sitio de Interes */}
                    <Route
                        path={`${PLACES_OF_INTEREST_ROUTER.path}/:id`}
                        element={<PlacesOfInterest />}
                    />

                    {/* Propiedad */}
                    <Route path={PROPERTIES_ROUTER.path} element={<Properties />} />
                    <Route path={`${PROPERTY_ROUTER.path}/:id`} element={<Property />} />

                    {/* Policy */}
                    <Route
                        path={PRIVACY_POLICY_ROUTER.path}
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        path={TERMS_AND_CONDITIONS_ROUTER.path}
                        element={<TermsAndConditions />}
                    />

                    {/* Dashboard */}
                    <Route path={DASHBOARD_ROUTER.path} element={<DashboardLayout />}>
                        <Route path={DASHBOARD_ROUTER.path} element={<DashboardPage />} />
                        <Route
                            path={DASHBOARD_PROPERTIES_ROUTER.path}
                            element={<DashboardProperties />}
                        />
                        <Route
                            path={DASHBOARD_FAVORITE_ROUTER.path}
                            element={<DashboardFavorite />}
                        />
                    </Route>
                </Route>

                <Route exact path={REGISTER_ROUTER.path} element={<Register />} />
                <Route path={SELLS_ROUTER.path} element={<Register />} />

                <Route path='*' element={<Navigate to={HOME_ROUTER.path} />} />
            </Routes>
        </BrowserRouter>
    </UserContextProvider>
)

export default App
