import * as React from 'react'

const PhoneIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95 95'>
        <defs>
            <style>{'.cls-3{fill:#fff}'}</style>
        </defs>
        <g>
            <rect
                width={95}
                height={95}
                rx={11.4}
                style={{
                    fill: '#062828',
                }}
            />
            <g>
                <g>
                    <g>
                        <path
                            style={{
                                fill: 'none',
                            }}
                            d='M17.11 17.11h60.8v60.8h-60.8z'
                        />
                        <path
                            className='cls-3'
                            d='M56.87 25.26H38.15a5.89 5.89 0 0 0-5.86 5.86V63.9a5.83 5.83 0 0 0 5.81 5.86h18.77a5.89 5.89 0 0 0 5.86-5.86V31.12a5.89 5.89 0 0 0-5.86-5.86Zm-22.25 5.86a3.56 3.56 0 0 1 3.53-3.53h18.72a3.57 3.57 0 0 1 3.53 3.53v25.52H34.62ZM60.4 63.9a3.55 3.55 0 0 1-3.53 3.52H38.15a3.54 3.54 0 0 1-3.53-3.52V59H60.4Z'
                        />
                        <path
                            className='cls-3'
                            d='M48.68 61h-2.34a1.14 1.14 0 0 0-1.17 1.11v2.39a1.15 1.15 0 0 0 1.12 1.17h2.38a1.15 1.15 0 0 0 1.17-1.12v-2.4A1.13 1.13 0 0 0 48.73 61Z'
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default PhoneIcon
