import React from 'react'
import PropTypes from 'prop-types'

// Componetns
import { Text } from '../Text'

// Icon
import { GoogleLogo } from '../Svg'

const ButtonGoogle = ({ textShort }) => {
    return (
        <button className='buttonGoogle'>
            <GoogleLogo />
            <Text>{textShort ? 'Google' : 'Registro con google'}</Text>
        </button>
    )
}

ButtonGoogle.propTypes = {
    textShort: PropTypes.string,
}

export default ButtonGoogle
