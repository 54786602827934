import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../../components/Title'
import { Button } from '../../../../../components/Button'
import { Text } from '../../../../../components/Text'
import { Maps } from '../../../../../components/Maps'

const StepTwo = ({ data, onSubmit, goBack }) => (
    <>
        <Title size='sm'>Es esta la dirección correcta ?</Title>
        <div className='modalRegisters_map'>
            <Maps lat={data.address.lat} lng={data.address.lng} />
        </div>
        <Text>Carrera 56 No. 51 - 81</Text>

        <div className='modalRegisters_btns'>
            <Button rounded onClick={onSubmit}>
        Si, Correcto
            </Button>
            <Button
                onClick={goBack}
                color='grey'
                textColor='gray'
                transparent
                underline
            >
        Arreglar dirección
            </Button>
        </div>
    </>
)

StepTwo.propTypes = {
    onSubmit: PropTypes.func, 
    data: PropTypes.object,
    goBack: PropTypes.func,
}

export default StepTwo
