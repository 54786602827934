import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Components
import {
    FeaturedPropertiesDetails,
    FeaturedPropertiesLocation,
    FeaturedPropertiesPrice,
} from '../FeaturedPropertiesList'
import { Text } from '../Text'
import { SavePropertie } from '../SavePropertie'
import { PropertiesInterestedList } from '../PropertiesInterested'

// Routes
import { PROPERTY_ROUTER } from '../../helpers/constants/routes'

const FeaturedPropertiesLarge = ({
    image,
    id,
    details,
    location,
    price,
    name,
    shadow,
    favorite,
    interested,
}) => {
    const classContainer = clsx({
        'propertiesLarge': interested,
    })
    const classFeatured = clsx('featuredPropertiesLarge', {
        'featuredPropertiesLarge_shadow': shadow,
    })
    return (
        <div className={classContainer}>
            <Link to={`${PROPERTY_ROUTER.path}/${id}`} className={classFeatured}>
                <div className='featuredPropertiesLarge_image'>
                    <img src={image} alt='Images' />
                    {favorite && <SavePropertie save={favorite} />}
                </div>

                <div className='featuredPropertiesLarge_content'>
                    {name && (
                        <Text bold color='primary'>
                            {name}
                        </Text>
                    )}
                    {details && (
                        <FeaturedPropertiesDetails
                            bed={details.bed}
                            bathroom={details.bathroom}
                            meters={details.meters}
                        />
                    )}

                    <FeaturedPropertiesLocation location={location} />

                    <FeaturedPropertiesPrice price={price} />
                </div>
            </Link>

            {interested && interested.length > 0 && (
                <PropertiesInterestedList interested={interested} />
            )}
        </div>
    )
}

FeaturedPropertiesLarge.propTypes = {
    image: PropTypes.string,
    id: PropTypes.string,
    details: PropTypes.object,
    location: PropTypes.string,
    price: PropTypes.string,
    name: PropTypes.string,
    shadow: PropTypes.bool,
    favorite: PropTypes.bool,
    interested: PropTypes.array,
}

export default FeaturedPropertiesLarge
