import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const Input = ({
    type = 'text',
    name,
    value,
    placeholder,
    iconStart,
    iconEnd,
    label,
    onChange,
    min,
    max,
    labelBold,
    customClass,
    children,
    error,
}) => {
    const classInputIcon = clsx({
        'inputIconStart': iconStart,
        'inputIconEnd': iconEnd,
        [customClass]: customClass,
    })

    return (
        <label className='inputCustom'>
            {label && (
                <span className={`${labelBold ? 'labelBold' : ''}`}>{label}</span>
            )}

            {children ? (
                children
            ) : (
                <div className='inputCustom_input'>
                    {iconStart && (
                        <div className='inputCustom_iconStart'>{iconStart}</div>
                    )}
                    <input
                        className={classInputIcon}
                        type={type}
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange && onChange}
                        min={min}
                        max={max}
                    />
                    {iconEnd && <div className='inputCustom_iconEnd'>{iconEnd}</div>}
                </div>
            )}

            {error && <span className='inputCustom_error'>{error}</span>}
        </label>
    )
}

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    iconStart: PropTypes.node,
    iconEnd: PropTypes.node,
    label: PropTypes.string,
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    labelBold: PropTypes.bool,
    customClass: PropTypes.string,
    children: PropTypes.node,
    error: PropTypes.string,
}

export default Input
