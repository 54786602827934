export const FIELD_KEY_NAME = 'name'
export const FIELD_KEY_ROOMS = 'rooms'
export const FIELD_KEY_BATHROOMS = 'bathrooms'
export const FIELD_KEY_LOCATION = 'location'
export const FIELD_KEY_TYPE = 'type'
export const FIELD_KEY_STRATUM = 'stratum'
export const FIELD_KEY_PARKINGLOT = 'parkingLot'
export const FIELD_KEY_LEVELS = 'levels'
export const FIELD_KEY_ANTIQUITY = 'antiquity'
export const FIELD_KEY_DESCRIPTION = 'description'
export const FIELD_KEY_EMBEDDED_VIDEO = 'embeddedVideo'
export const FIELD_KEY_TOTAL_AREA = 'totalArea'
export const FIELD_KEY_BUILT_AREA = 'builtArea'
export const FIELD_KEY_CONTANT_PHONE = 'contactPhone'
export const FIELD_KEY_PRICE = 'price'
export const FIELD_KEY_IMG_PRIMARY = 'imgPrimary'
export const FIELD_KEY_FEATURES = 'features'
export const FIELD_KEY_GALERY = 'galery'
export const FIELD_KEY_PLACES_OF_INTEREST = 'placesOfInterest'
export const FIELD_KEY_FEATURED_PROPERTY = 'featuredProperty'
export const FIELD_KEY_PROPERTY_CODE = 'propertyCode'
export const FIELD_KEY_ADMINISTRATION = 'administration'
export const FIELD_KEY_CREATED = 'create'
export const FIELD_KEY_CONTENT = 'content'
export const FILED_KEY_TITLE = 'title'
export const FIELD_KEY_PROPERTIES = 'properties'
export const FIELD_KEY_IMG_HEADER = 'imgHeader'
export const FIELD_ORDER_BY = 'orderBy'
export const FIELD_KEY_LAT = 'lat'
export const FIELD_KEY_LNG = 'lng'
export const FIELD_KEY_ADRRESS = 'address'
export const FIELD_KEY_EMAIL = 'email'
export const FIELD_KEY_PHONE = 'phone'
export const FIELD_KEY_PRIVACITY = 'privacity'

export const FIELDS_PROPERTY = {
    [FIELD_KEY_NAME]: 'Nombre',
    [FIELD_KEY_ROOMS]: 'Habitaciones',
    [FIELD_KEY_BATHROOMS]: 'Banos',
    [FIELD_KEY_LOCATION]: 'Ubicacion',
    [FIELD_KEY_TYPE]: 'Tipo',
    [FIELD_KEY_STRATUM]: 'Estrato',
    [FIELD_KEY_PARKINGLOT]: 'Parqueaderos',
    [FIELD_KEY_LEVELS]: 'Niveles',
    [FIELD_KEY_ANTIQUITY]: 'Antiguedad',
    [FIELD_KEY_DESCRIPTION]: 'Descripcion',
    [FIELD_KEY_EMBEDDED_VIDEO]: 'Video_embebido',
    [FIELD_KEY_TOTAL_AREA]: 'Area_total',
    [FIELD_KEY_BUILT_AREA]: 'Area_construida',
    [FIELD_KEY_CONTANT_PHONE]: 'Telefono_de_contacto',
    [FIELD_KEY_PRICE]: 'Valor',
    [FIELD_KEY_IMG_PRIMARY]: 'Imagen_Principal',
    [FIELD_KEY_FEATURES]: 'Caracteristicas',
    [FIELD_KEY_GALERY]: 'Galeria',
    [FIELD_KEY_PLACES_OF_INTEREST]: 'Sitios_de_Interes',
    [FIELD_KEY_FEATURED_PROPERTY]: 'Destacado',
    [FIELD_KEY_PROPERTY_CODE]: 'Codigo_propiedad',
    [FIELD_KEY_ADMINISTRATION]: 'Administracion',
    [FIELD_KEY_LAT]: 'Latitud',
    [FIELD_KEY_LNG]: 'Longitud',
}

export const FIELDS_PLACES_OF_INTEREST = {
    [FIELD_KEY_CONTENT]: 'Contenido',
    [FIELD_KEY_NAME]: 'Nombre',
    [FIELD_KEY_IMG_PRIMARY]: 'Imagen_Principal',
    [FIELD_KEY_LOCATION]: 'Ubicacion',
    [FIELD_KEY_CREATED]: 'publishedAt',
    [FIELD_KEY_DESCRIPTION]: 'Descripcion',
    [FIELD_KEY_FEATURED_PROPERTY]: 'Destacado',
    [FIELD_KEY_PROPERTIES]: 'Propiedades',
    [FIELD_KEY_IMG_HEADER]: 'Imagen_Encabezado',
}

export const FIELDS_FEATUES = {
    [FIELD_KEY_NAME]: 'Nombre',
}

export const FIELDS_BLOG = {
    [FIELD_KEY_CONTENT]: 'Contenido',
    [FIELD_KEY_DESCRIPTION]: 'Descripcion',
    [FIELD_KEY_FEATURED_PROPERTY]: 'Destacado',
    [FIELD_KEY_IMG_PRIMARY]: 'Imagen_Principal',
    [FILED_KEY_TITLE]: 'Titulo',
    [FIELD_KEY_CREATED]: 'publishedAt',
    [FIELD_KEY_PROPERTIES]: 'Propiedades',
    [FIELD_KEY_IMG_HEADER]: 'Imagen_Encabezado',
}

export const FIELDS_HOME = {
    header: 'Encabezado',
    header_img: 'Imagen_Encabezado',
    title_property: 'Titulo_propiedades',
    description_property: 'Descripcion_propiedades',
    title_blog: 'Titulo_blog',
    description_blog: 'Descripcion_blog',
    title_places_of_interest: 'Titulo_sitios_de_interes',
    description_places_of_interest: 'Descripcion_sitios_de_interes',
    title_social_media: 'Titulo_redes_sociales',
    description_social_media: 'Descripcion_redes_sociales',
    social_media_img: 'Imagen_redes_sociales',
}

export const FIELD_ABOUT_US = {
    title_header: 'Titulo_encabezado',
    description_header: 'Descripcion_encabezado',
    title_one: 'Titulo_1',
    subTitle_one: 'Subtitulo_1',
    description_one: 'Descripcion_1',
    title_two: 'Titulo_2',
    subTitle_two: 'Subtitulo_2',
    description_two: 'Descripcion_2',
    feature_one: 'Caracteristica_1',
    feature_two: 'Caracteristica_2',
    feature_three: 'Caracteristica_3',
    feature_four: 'Caracteristica_4',
    feature_five: 'Caracteristica_5',
    feature_six: 'Caracteristica_6',
    image_one: 'Imagen_1',
    image_two: 'Imagen_2',
    image_header: 'Imagen_Encabezado',
}
