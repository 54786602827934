import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Components
import { Input } from '../../../../../components/Input'
import { Title } from '../../../../../components/Title'
import { Button } from '../../../../../components/Button'
import { AutoComplete } from '../../../../../components/Maps'

// Fields
import { FIELD_KEY_ADRRESS } from '../../../../../utils/fields'

const StepOne = ({ onChange }) => {
    const [ dataStepOne, setDataStepOne ] = useState({
        address: '',
        propertie_type: '',
        propertie: '',
        city: '',
    })

    const handleChange = ({ field, value }) => {
    // ! validamos datos

        const update = {
            [field]: value,
        }

        // Actualizamos la ciudad si llega del autocompletado de google
        if (field === FIELD_KEY_ADRRESS) {
            if (value && value.city) {
                update.city = value.city
            }
        }

        setDataStepOne({
            ...dataStepOne,
            ...update,
        })
    }

    const handleConfirm = () => {
    // ! validamos que todos los campos esten llenos
        onChange({ data: dataStepOne })
    }

    return (
        <>
            <Title size='sm'>Ingresa los datos de tu propiedad</Title>

            <Input label='Dirección'>
                <AutoComplete
                    placeholder='Dirección de su propiedad'
                    onChange={(value) =>
                        handleChange({ field: FIELD_KEY_ADRRESS, value })
                    }
                />
            </Input>

            <Input
                label='Apartamento, unidad, suite, etc.'
                placeholder='Detalles'
                name='propertie_type'
                value={dataStepOne.propertie_type}
                onChange={(e) =>
                    handleChange({ field: e.target.name, value: e.target.value })
                }
            />
            <Input
                label='Departamento'
                placeholder='Departamento'
                name='propertie'
                value={dataStepOne.propertie}
                onChange={(e) =>
                    handleChange({ field: e.target.name, value: e.target.value })
                }
            />
            <Input
                label='Ciudad'
                placeholder='Ciudad'
                name='city'
                value={dataStepOne.city}
                onChange={(e) =>
                    handleChange({ field: e.target.name, value: e.target.value })
                }
            />

            <div className='modalRegisters_btns'>
                <Button rounded onClick={handleConfirm}>
          Aceptar
                </Button>
                <Button color='grey' textColor='gray' transparent underline>
          Cancelar
                </Button>
            </div>
        </>
    )
}

StepOne.propTypes = {
    onChange: PropTypes.func,
}

export default StepOne
