import React from 'react'
import PropTypes from 'prop-types'

// Components
import { PlacesAndSectorsCard } from './'

// Utils
import { FIELDS_PLACES_OF_INTEREST } from '../../utils/fields'

const PlacesAndSectorsList = ({ data }) => {
    return (
        <div className='placesAndSectors_list'>
            {data.map((item) => (
                <PlacesAndSectorsCard
                    key={item.id}
                    id={item.id}
                    location={item.attributes[FIELDS_PLACES_OF_INTEREST.location]}
                    image={
                        item.attributes[FIELDS_PLACES_OF_INTEREST.imgPrimary].data
                            .attributes.url
                    }
                />
            ))}
        </div>
    )
}

PlacesAndSectorsList.propTypes = {
    data: PropTypes.array,
}

export default PlacesAndSectorsList
