import React from 'react'
import PropTypes from 'prop-types'

// Componetns
import { TitleIcon } from '../../../../components/Title'
import { Text } from '../../../../components/Text'

// Icons
import { FeaturesIcon, CheckIcon } from '../../../../components/Svg'

// Utils
import { FIELDS_PROPERTY } from '../../../../utils/fields'

const ListFeatured = ({ data }) => {
    return (
        <div className='listFeatured'>
            <TitleIcon
                sizeIcon='xl'
                icon={<FeaturesIcon />}
                title='Caracteristicas'
            />

            <div className='listFeatured_list'>
                {data[FIELDS_PROPERTY.features].data.map((item) => (
                    <div key={item.id} className='listFeatured_list_item'>
                        <CheckIcon color='#77878B' />
                        <Text color='gray'>{item.attributes.Nombre}</Text>
                    </div>
                ))}
            </div>
        </div>
    )
}

ListFeatured.propTypes = {
    data: PropTypes.object, 
}

export default ListFeatured
