import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../Title'
import { FeaturedPropertiesList } from '../FeaturedPropertiesList'
import { RenderMarkDown } from '../RenderMarkDown'

const FeaturedProperties = ({ title, description, listProperty }) => {
    return (
        <div className='container'>
            <div className='content featuredProperties'>
                <Title size='lg'>{title}</Title>
                <RenderMarkDown>{description}</RenderMarkDown>

                <div className='featuredProperties_list'>
                    <FeaturedPropertiesList data={listProperty} />
                </div>
            </div>
        </div>
    )
}

FeaturedProperties.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    listProperty: PropTypes.array,
}

export default FeaturedProperties
