import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Context = React.createContext({})

export function UserContextProvider({ children }) {
    const [ jwt, setJWT ] = useState(() => window.sessionStorage.getItem('jwt'))
    const [ user, setUser ] = useState(
        () => JSON.parse(window.sessionStorage.getItem('user')) || '',
    )

    return (
        <Context.Provider value={{ jwt, setJWT, user, setUser }}>
            {children}
        </Context.Provider>
    )
}

UserContextProvider.propTypes = {
    children: PropTypes.node,
}

export default Context
