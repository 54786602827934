import React from 'react'
import PropTypes from 'prop-types'

// Components
import { FeaturedPropertiesLargeList } from '../../../../components/FeaturedPropertiesLargeList'

const ListFavorite = ({ data }) => {
    return (
        <div className='listFavorite'>
            <FeaturedPropertiesLargeList data={data} shadow />
        </div>
    )
}

ListFavorite.propTypes = {
    data: PropTypes.array,
}

export default ListFavorite
