import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const Select = ({
    children,
    onChange,
    name,
    label,
    labelBold,
    value,
    icon,
}) => {
    const classLabel = clsx(
        {
            'labelBold': labelBold,
        },
        'inputCustom',
    )

    const containerSelect = clsx(
        {
            'selectIcon': icon,
        },
        'customSelect',
    )

    return (
        <label htmlFor={name} className={classLabel}>
            {label && label}
            <div className={containerSelect}>
                <select name={name} id={name} onChange={onChange} value={value}>
                    {children}
                </select>
                {icon && icon}
            </div>
        </label>
    )
}

Select.propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    labelBold: PropTypes.string,
    value: PropTypes.number,
    icon: PropTypes.node,
}

export default Select
