import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'

// Icons
import { LocationIcon, LocationFullIcon } from '../Svg'

const FeaturedPropertiesLocation = ({ location, size, iconFull }) => {
    return (
        <Text
            size={size}
            color='gray'
            classNameCustom={`textLocation textLocation_svg${size}`}
        >
            {iconFull ? <LocationFullIcon /> : <LocationIcon color='#30A68F' />}
            {location}
        </Text>
    )
}

FeaturedPropertiesLocation.propTypes = {
    location: PropTypes.string,
    size: PropTypes.string,
    iconFull: PropTypes.bool,
}

export default FeaturedPropertiesLocation
