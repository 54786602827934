import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'
import { Button } from '../../../../components/Button'
import { RenderMarkDown } from '../../../../components/RenderMarkDown'

const CardPost = ({ image, date, title, description, goTo }) => {
    return (
        <div className='cardPost'>
            <img className='cardPost_image' src={image} alt={title} />

            <Text size='sm' color='gray'>
                {date}
            </Text>
            <Title size='md'>{title}</Title>
            <RenderMarkDown>{description}</RenderMarkDown>
            <Button to={goTo} rounded color='secundary'>
                Leer más
            </Button>
        </div>
    )
}

CardPost.propTypes = {
    image: PropTypes.string, 
    date: PropTypes.string,
    title: PropTypes.string, 
    description: PropTypes.string, 
    goTo: PropTypes.string,
}

export default CardPost
