import { FIELDS_PROPERTY } from './fields'

export const GENERAL_IGNORER = [ 'createdAt', 'updatedAt', 'publishedAt' ]

export const IGNORER_BASIC_INFO = [
    FIELDS_PROPERTY.name,
    FIELDS_PROPERTY.description,
    FIELDS_PROPERTY.embeddedVideo,
    FIELDS_PROPERTY.contactPhone,
    FIELDS_PROPERTY.imgPrimary,
    FIELDS_PROPERTY.features,
    FIELDS_PROPERTY.galery,
    FIELDS_PROPERTY.placesOfInterest,
    FIELDS_PROPERTY.featuredProperty,
    FIELDS_PROPERTY.lat,
    FIELDS_PROPERTY.lng,
    ...GENERAL_IGNORER,
]
