import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import { Title } from '../Title'
import {
    FeaturedPropertiesPrice,
    FeaturedPropertiesLocation,
    FeaturedPropertiesDetails,
} from './'
import { SavePropertie } from '../SavePropertie'

// Router
import { PROPERTY_ROUTER } from '../../helpers/constants/routes'

const FeaturedPropertiesCard = ({
    save,
    bed,
    bathroom,
    meters,
    name,
    location,
    price,
    id,
    image,
}) => {
    return (
        <Link
            to={`${PROPERTY_ROUTER.path}/${id}`}
            className='featuredProperties_card'
        >
            <SavePropertie save={save} />
            <img src={image} alt='Images' />

            <div className='featuredProperties_card_content'>
                <div className='featuredProperties_card_content_details'>
                    <FeaturedPropertiesDetails
                        bed={bed}
                        bathroom={bathroom}
                        meters={meters}
                    />

                    <Title size='sm'>{name}</Title>

                    <FeaturedPropertiesLocation location={location} />
                </div>

                <FeaturedPropertiesPrice price={price} />
            </div>
        </Link>
    )
}

FeaturedPropertiesCard.propTypes = {
    save: PropTypes.bool,
    bed: PropTypes.number,
    bathroom: PropTypes.number,
    meters: PropTypes.number,
    name: PropTypes.string,
    location: PropTypes.string,
    price: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
}

export default FeaturedPropertiesCard
