import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../../../../../../components/Text'

const RecentActivityItem = ({ image, username, activity }) => {
    return (
        <div className='recentActivityItem'>
            <div className='recentActivityItem_circle' />
            <img src={image} alt='activity' />
            <div className='recentActivityItem_text'>
                <Text size='xs'>{username}</Text>
                <Text size='xs'>{activity}</Text>
            </div>
        </div>
    )
}

RecentActivityItem.propTypes = {
    image: PropTypes.string,
    username: PropTypes.string,
    activity: PropTypes.string, 
}

export default RecentActivityItem
