import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'

const LoadingCardProperty = ({ count = 1 }) => (
    <>
        {new Array(count).fill('').map((item, index) => (
            <ContentLoader
                key={index}
                speed={2}
                viewBox='0 0 400 460'
                backgroundColor='#f5f5f5'
                foregroundColor='#ecebeb'
            >
                <rect x='3' y='0' rx='2' ry='2' width='383' height='268' />
                <rect x='4' y='318' rx='4' ry='4' width='376' height='21' />
                <rect x='100' y='276' rx='4' ry='4' width='199' height='20' />
                <rect x='5' y='356' rx='4' ry='4' width='376' height='21' />
                <rect x='4' y='397' rx='4' ry='4' width='376' height='21' />
            </ContentLoader>
        ))}
    </>
)

LoadingCardProperty.propTypes = {
    count: PropTypes.number,
}

export default LoadingCardProperty
