import * as React from 'react'
import PropTypes from 'prop-types'

const CheckIcon = ({ color = '#062828' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
        <path
            fill={color}
            d='M438.6 105.4c12.5 12.5 12.5 32.7 0 45.2l-256 256c-12.5 12.5-32.7 12.5-45.2 0L9.372 278.6c-12.496-12.5-12.496-32.7 0-45.2 12.498-12.5 32.758-12.5 45.258 0L159.1 338.7l234.3-233.3c12.5-12.52 32.7-12.52 45.2 0z'
        />
    </svg>
)

CheckIcon.propTypes = {
    color: PropTypes.string,
}

export default CheckIcon
