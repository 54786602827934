import React from 'react'

// Components
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'
import { Button } from '../../../../components/Button'

// Icons
import { CloudIcon } from '../../../../components/Svg'

// Social
import { SOCIAL_WA } from '../../../../helpers/constants/socialMedia'

const PropertyContact = () => {
    return (
        <div className='propertyContact'>
            <Title size='md' center>
        ¡Agenda tu visita ahora mismo!
            </Title>
            <Text color='gray' classNameCustom='textCenter'>
        Uno de nuestros asesores está disponible para resolver tus dudas de
        inmediato.
            </Text>
            <a
                href={SOCIAL_WA.url}
                target='_blank'
                rel='noreferrer'
                className='textDecorateNone'
            >
                <Button className='propertyContact_btn' color='fourth' rounded icon>
                    <CloudIcon />
          Contáctanos
                </Button>
            </a>
        </div>
    )
}

export default PropertyContact
