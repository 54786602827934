import * as React from 'react'
import PropTypes from 'prop-types'

const TiktokIcon = ({ color = '#062828' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 70'>
        <g transform='translate(.25)'>
            <path
                d='M43.124 31.81a13.2 13.2 0 0 0 7.7 2.463v-5.522a7.8 7.8 0 0 1-1.617-.169v4.346a13.2 13.2 0 0 1-7.7-2.462v11.269a10.215 10.215 0 0 1-15.9 8.48 10.214 10.214 0 0 0 17.514-7.136V31.81Zm1.995-5.571a7.7 7.7 0 0 1-1.995-4.506v-.71h-1.532a7.731 7.731 0 0 0 3.527 5.216Zm-15.942 19.65a4.672 4.672 0 0 1 5.136-7.277v-5.644a10.305 10.305 0 0 0-1.616-.093v4.393a4.67 4.67 0 0 0-3.519 8.621Z'
                fill={color}
                opacity={0.2}
            />
            <path
                d='M41.508 30.468a13.2 13.2 0 0 0 7.7 2.462v-4.348a7.729 7.729 0 0 1-4.088-2.342 7.731 7.731 0 0 1-3.527-5.216h-4.026v22.055a4.672 4.672 0 0 1-8.39 2.811 4.671 4.671 0 0 1 3.52-8.622v-4.394a10.206 10.206 0 0 0-7.086 17.341 10.215 10.215 0 0 0 15.9-8.481V30.468Z'
                fill={color}
            />
            <path
                d='M49.207 28.579v-1.172a7.7 7.7 0 0 1-4.088-1.168 7.722 7.722 0 0 0 4.088 2.34Zm-7.615-7.556q-.055-.315-.085-.634v-.71H35.95v22.056a4.674 4.674 0 0 1-6.773 4.155 4.672 4.672 0 0 0 8.39-2.811V21.023Zm-8.895 11.856v-1.256a10.315 10.315 0 0 0-1.4-.1 10.206 10.206 0 0 0-5.686 18.692 10.206 10.206 0 0 1 7.086-17.336Z'
                fill={color}
                opacity={0.5}
            />
        </g>
    </svg>
)

TiktokIcon.propTypes = {
    color: PropTypes.string,
}

export default TiktokIcon
