import * as React from 'react'
import PropTypes from 'prop-types'

const userBuyIcon = ({ color = '#062828' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox='0 0 28.233 32.233'
    >
        <g data-name="Grupo 4954" fill={color}>
            <path
                d="M27.47 9.597Q21.338 4.994 15.201.396a1.68 1.68 0 0 0-2.177.009L.753 9.605a1.818 1.818 0 0 0-.615.755 1.693 1.693 0 0 0 1.639 2.345.424.424 0 0 1 .482.472v14.575a2.833 2.833 0 0 0 3.019 3.022H18.97a2.159 2.159 0 0 0 .282-.006.556.556 0 0 0 .506-.577.544.544 0 0 0-.507-.54 2.441 2.441 0 0 0-.353-.006H5.312a1.719 1.719 0 0 1-1.925-1.905q0-7.339-.008-14.678c0-.293.067-.362.36-.361 3.458.011 6.917.008 10.374.008s6.917 0 10.374-.008c.294 0 .361.069.36.361q-.016 7.022-.008 14.043a1.985 1.985 0 0 0 .008.352.561.561 0 0 0 1.112 0 1.9 1.9 0 0 0 .008-.352V13.17a.408.408 0 0 1 .457-.462 1.7 1.7 0 0 0 1.04-3.112Zm-.4 1.6a.537.537 0 0 1-.469.374 2.73 2.73 0 0 1-.317 0H1.766a.572.572 0 0 1-.352-1.06Q2.909 9.4 4.401 8.278l9.191-6.891c.454-.34.59-.345 1.028-.016l12.095 9.073a1.813 1.813 0 0 1 .221.174.525.525 0 0 1 .131.583Z"
            />
            <path
                d="M22.795 26.895q-1.21-1.21-2.421-2.417a.575.575 0 0 0-.844-.1c-.162.12-.259.412-.464.357-.177-.048-.307-.276-.457-.425a.615.615 0 0 0-.075-.074c-.2-.137-.174-.259-.033-.447a6.047 6.047 0 0 0 1.172-4.785 6.217 6.217 0 0 0-6.7-5.151 6.352 6.352 0 0 0-5.637 6.654 5.869 5.869 0 0 0 3.295 5.014 6 6 0 0 0 6.382-.316c.543-.345.522-.341 1.011.1.246.224.267.353 0 .584a.578.578 0 0 0-.038.988q2.715 2.722 5.437 5.437a.572.572 0 0 0 .945-.008c.462-.453.917-.912 1.372-1.37.431-.434.431-.66 0-1.1q-1.47-1.473-2.943-2.943ZM13.56 25.13a5.081 5.081 0 1 1 5.073-5.063 5.088 5.088 0 0 1-5.073 5.063Zm10.8 5.6c-.461.457-.46.459-.92 0-1.371-1.37-2.737-2.747-4.118-4.107-.2-.192-.177-.282.007-.459.582-.561.574-.569 1.152.008l3.988 3.988c.073.073.141.15.253.269-.128.106-.254.194-.362.3Z"
            />
            <path
                d="M13.557 16.442c.041-.314-.087-.365-.368-.336a3.967 3.967 0 0 0-3.533 3.336q-.1.6.5.6a1.207 1.207 0 0 1 .175 0c.294.048.393-.047.436-.37a2.782 2.782 0 0 1 2.105-2.37c.222-.054.556-.017.645-.151.114-.172.011-.476.042-.719Z"
            />
        </g>
    </svg>
)

userBuyIcon.propTypes = {
    color: PropTypes.string,
}

export default userBuyIcon
