import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Components
import { Modal } from '../../../../components/Modal'
import { StepOne, StepTwo } from './Steps'

const STEP_SET_INFO = 1
const STEP_SHOW_INFO = 2

const ModalRegister = ({ close, onRegister }) => {
    const [ step, setStep ] = useState(STEP_SET_INFO)
    const [ registerData, setRegisterData ] = useState({})

    const handleUpdateRegisterData = ({ data }) => {
        setRegisterData(data)
        setStep(STEP_SHOW_INFO)
    }

    const handleFinishSteps = () => {
        onRegister({ data: registerData })
    }

    return (
        <Modal
            onClose={close}
            header={
                step === 1 ? 'Agrega Tu Propiedad En Venta' : 'Confirma Tu Información'
            }
        >
            <div className='modalRegister modal-md'>
                {step === 1 ? (
                    <StepOne onChange={handleUpdateRegisterData} />
                ) : (
                    <StepTwo
                        data={registerData}
                        goBack={() => setStep(STEP_SET_INFO)}
                        onSubmit={handleFinishSteps}
                    />
                )}
            </div>
        </Modal>
    )
}

ModalRegister.propTypes = {
    close: PropTypes.func,
    onRegister: PropTypes.func,
}

export default ModalRegister
