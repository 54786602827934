import React, { useState, useEffect } from 'react'

// Hooks
import { usePagination } from '../../hooks'

// Components
import { Title } from '../../components/Title'
import { Pagination } from '../../components/Pagination'
import { ListFavorite } from './Components/ListFavorite'

// Mock
import { FEATURED_PROPERTIES_MOCK } from '../../mocks/featuredPropertiesMock'

const LIMIT_ITEM_FOR_PAGE = 3

const DashboardFavorite = () => {
    const [ listFeatured, setLisfeatured ] = useState([])
    const { page, handleChangePage, totalPage } = usePagination({
        data: FEATURED_PROPERTIES_MOCK.filter((item) => item.save),
        itemForPage: LIMIT_ITEM_FOR_PAGE,
    })

    useEffect(() => {
        setLisfeatured(
            FEATURED_PROPERTIES_MOCK.filter((item) => item.save).slice(
                (page - 1) * LIMIT_ITEM_FOR_PAGE,
                (page - 1) * LIMIT_ITEM_FOR_PAGE + LIMIT_ITEM_FOR_PAGE,
            ),
        )
    }, [ page ])

    return (
        <div className='dashboardPage'>
            <Title size='lg'>Favoritos</Title>

            <ListFavorite data={listFeatured} />

            <Pagination
                page={page}
                countPage={totalPage}
                onChangePage={handleChangePage}
            />
        </div>
    )
}

export default DashboardFavorite
