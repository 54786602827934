import Image from '../assets/images/hero-img-desktop.jpg'

export const RECENT_ACTIVITY_MOCKS = [
    {
        id: 1,
        image: Image,
        username: '@NombreUsuario',
        activity: 're-agenda la cita',
    },
    {
        id: 2,
        image: Image,
        username: '@NombreUsuario',
        activity: 're-agenda la cita',
    },
    {
        id: 3,
        image: Image,
        username: '@NombreUsuario',
        activity: 're-agenda la cita',
    },
]
