import React from 'react'
import PropTypes from 'prop-types'

// Icons
import { SaveIcon } from '../Svg'

const SavePropertie = ({ save }) => {
    return (
        <div className='savePropertie'>
            <SaveIcon color='#30A68F' active={save} />
        </div>
    )
}

SavePropertie.propTypes = {
    save: PropTypes.bool,
}

export default SavePropertie
