import React from 'react'
import PropTypes from 'prop-types'

// Component
import { FeaturedPropertiesCard } from './'

// Utils
import { FIELDS_PROPERTY } from '../../utils/fields'

// Assets
import ImageDefault from '../../assets/images/default.png'

const FeaturedPropertiesList = ({ data }) => {
    return (
        <>
            {data.map((item) => (
                <FeaturedPropertiesCard
                    key={item.id}
                    id={item.id}
                    save={item.attributes.save}
                    bed={item.attributes[FIELDS_PROPERTY.rooms]}
                    bathroom={item.attributes[FIELDS_PROPERTY.bathrooms]}
                    meters={item.attributes[FIELDS_PROPERTY.totalArea]}
                    name={item.attributes[FIELDS_PROPERTY.name]}
                    location={item.attributes[FIELDS_PROPERTY.location]}
                    price={item.attributes[FIELDS_PROPERTY.price]}
                    image={
                        item.attributes[FIELDS_PROPERTY.imgPrimary]?.data?.attributes
                            ?.url || ImageDefault
                    }
                />
            ))}
        </>
    )
}

FeaturedPropertiesList.propTypes = {
    data: PropTypes.array,
}

export default FeaturedPropertiesList
