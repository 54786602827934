import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'
import {
    FeaturedPropertiesLocation,
    FeaturedPropertiesPrice,
} from '../../../../components/FeaturedPropertiesList'
import { 
    FacebookShare, 
    WhatsAppShare,
    LinkShare,
} from '../../../../components/SocialShare'

// Fields
import {
    FIELDS_PROPERTY,
    FIELD_KEY_NAME,
    FIELD_KEY_PROPERTY_CODE,
} from '../../../../utils/fields'

const PropertyHeader = ({ data: { attributes } }) => {
    return (
        <div className='propertyHeader'>
            <div className='propertyHeader_name'>
                <Title size='md'>
                    {attributes[FIELDS_PROPERTY[FIELD_KEY_NAME]]}
                    <span className='propertyHeader_code'>
                        {attributes[FIELDS_PROPERTY[FIELD_KEY_PROPERTY_CODE]]}
                    </span>
                </Title>

                <div className='propertyHeader_share'>
                    <Text color='gray'>Compartir</Text>
                    <LinkShare />
                    <FacebookShare />
                    <WhatsAppShare />
                </div>
            </div>

            <FeaturedPropertiesLocation
                size='xl'
                location={attributes.Ubicacion}
                iconFull
            />
            <FeaturedPropertiesPrice price={attributes.Valor} />
        </div>
    )
}

PropertyHeader.propTypes = {
    data: PropTypes.object,
}

export default PropertyHeader
