import Image from '../assets/images/hero-img-desktop.jpg'
import ImageInterested from '../assets/images/mockProfile.png'

export const FEATURED_PROPERTIES_MOCK = [
    {
        id: 13,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 1,
        bathroom: 1,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: false,
        interested: [],
    },
    {
        id: 1,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 1,
        bathroom: 1,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
        ],
    },
    {
        id: 2,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 1,
        bathroom: 1,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [],
    },
    {
        id: 3,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 1,
        bathroom: 1,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
        ],
    },
    {
        id: 4,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 2,
        bathroom: 2,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [],
    },
    {
        id: 5,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 2,
        bathroom: 2,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
        ],
    },
    {
        id: 6,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 2,
        bathroom: 2,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
        ],
    },
    {
        id: 7,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 3,
        bathroom: 3,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
        ],
    },
    {
        id: 8,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 3,
        bathroom: 3,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
        ],
    },
    {
        id: 9,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 3,
        bathroom: 3,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [],
    },
    {
        id: 10,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 4,
        bathroom: 4,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [],
    },
    {
        id: 11,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 4,
        bathroom: 4,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
            {
                image: ImageInterested,
                name: '@NombreUsuario',
                email: 'email@ejemplo.com',
            },
        ],
    },
    {
        id: 12,
        name: 'Apartamento en San Lucas - E200',
        image: Image,
        bed: 4,
        bathroom: 4,
        meters: '60.000m2',
        location: 'Envigado',
        price: '476.092.000',
        save: true,
        interested: [],
    },
]
