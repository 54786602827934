import React from 'react'

// Const
import { URL_TIERRAS } from '../../const/urlTierras'

// IMAGES
import LogoWhite from '../../assets/images/logoTierrasWhite.svg'

const LogoTierras = () => {
    return (
        <a href={URL_TIERRAS} target='_blank' rel='noreferrer'>
            <img src={LogoWhite} alt='Logo Elemental' />
        </a>
    )
}

export default LogoTierras
