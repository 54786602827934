import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Components
import { BlogHero } from '../Blog/components/BlogHero'
import { Text } from '../../components/Text'
import { FeaturedProperties } from '../../components/FeaturedProperties'
import { LoadingPost } from '../../components/Loading'
import { RenderMarkDown } from '../../components/RenderMarkDown'
import { FacebookShare, WhatsAppShare } from '../../components/SocialShare'

// Fields
import {
    FIELDS_BLOG,
    FIELD_KEY_CONTENT,
    FIELD_KEY_CREATED,
    FIELD_KEY_DESCRIPTION,
    FIELD_KEY_IMG_PRIMARY,
    FIELD_KEY_PROPERTIES,
    FILED_KEY_TITLE,
    FIELD_KEY_IMG_HEADER,
} from '../../utils/fields'

// Routes
import { BLOG_ROUTER } from '../../helpers/constants/routes'

// Hooks
import { useBlog } from '../../hooks'

// Helpers
import { scrollTop } from '../../helpers/utils'

const BlogPost = () => {
    const { postBlog, loadingBlog, getBlog } = useBlog()
    const { id } = useParams()

    useEffect(() => {
        (async () => {
            try {
                scrollTop()
                await getBlog({ id })
            } catch (e) {
                console.error(e)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ])

    if (loadingBlog) return <LoadingPost />

    return (
        <div className='blogPost'>
            <BlogHero
                image={
                    postBlog.attributes[FIELDS_BLOG[FIELD_KEY_IMG_HEADER]].data.attributes
                        .url
                }
                title={postBlog.attributes[FIELDS_BLOG[FILED_KEY_TITLE]]}
                description={postBlog.attributes[FIELDS_BLOG[FIELD_KEY_DESCRIPTION]]}
                date={postBlog.attributes[FIELDS_BLOG[FIELD_KEY_CREATED]]}
                goBack={BLOG_ROUTER.path}
            />

            <div className='blogPost_container'>
                <div className='blogPost_content'>
                    <img
                        src={
                            postBlog.attributes[FIELDS_BLOG[FIELD_KEY_IMG_PRIMARY]].data
                                .attributes.url
                        }
                        alt='Post Header'
                    />

                    <RenderMarkDown>
                        {postBlog.attributes[FIELDS_BLOG[FIELD_KEY_CONTENT]]}
                    </RenderMarkDown>

                    <div className='blogPost_share'>
                        <Text bold>Comparte</Text>

                        <div className='blogPost_share_social'>
                            <FacebookShare />
                            <WhatsAppShare />
                        </div>
                    </div>
                </div>
            </div>

            {postBlog.attributes[FIELDS_BLOG[FIELD_KEY_PROPERTIES]].data.length >
        0 && (
                <div className='container'>
                    <div className='content'>
                        <FeaturedProperties
                            title='Propiedades destacadas en esta zona'
                            description='Sé el primero en conocer estas propiedades antes que muchas personas.'
                            listProperty={
                                postBlog.attributes[FIELDS_BLOG[FIELD_KEY_PROPERTIES]].data
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default BlogPost
