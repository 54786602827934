import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

// Hooks
import { useLogin } from '../../hooks'

// Components
import Logo from '../Logo/Logo'
import { NavigateCustom } from '../NavigateCustom'
import { ButtonLogin } from '../Button'
import { Avatar } from '../Avatar'

// Routes
import { DASHBOARD_ROUTER } from '../../helpers/constants/routes'

// Icons
import { MenuIcon, CloseIcon, NotificationIcon } from '../Svg'

// Helpers
import { NAVBAR_ROUTER, LIST_ROUTER } from '../../helpers/constants/routes'

// Mock
import ImageMock from '../../assets/images/mockProfile.png'

const ACTIVE_NAVBAR_PX = 40

const Header = () => {
    const [ activeNavbar, setActiveNavbar ] = useState(false)
    const menuMobile = useRef(null)
    const location = useLocation()
    const isHome = location.pathname === '/'
    const { isLogged } = useLogin()

    const handleMenuMobile = () => {
        menuMobile.current.classList.toggle('activeMenuMobile')
    }

    const changeBackgroundNavbar = () => {
        if (window.scrollY >= ACTIVE_NAVBAR_PX && !activeNavbar) {
            setActiveNavbar(true)
        } else if (window.scrollY < ACTIVE_NAVBAR_PX && activeNavbar) {
            setActiveNavbar(false)
        }
    }

    if (isHome) {
        window.addEventListener('scroll', changeBackgroundNavbar)
    } else {
        if (!activeNavbar) setActiveNavbar(true)
    }

    const classColorNavbar = clsx(
        {
            'activeNavbar': activeNavbar,
            'header_absolute': isHome,
        },
        'container',
    )

    return (
        <div className='positionNavbar zIndex-7'>
            {/* {!isLogged && (
                <div className='contianer bg_grey'>
                    <div className='content'>
                        <div className='header_login_mobile'>
                            <ButtonLogin changeColor={true} />
                        </div>
                    </div>
                </div>
            )} */}

            <div className={classColorNavbar}>
                <div className='content'>
                    <div className='header'>
                        <Logo color={`${activeNavbar ? 'black' : 'white'}`} />

                        <div className='header_navigation'>
                            {LIST_ROUTER[NAVBAR_ROUTER].map((item) => {
                                if (item.disabledAuth === isLogged) return ''

                                return (
                                    <NavigateCustom
                                        key={item.name}
                                        url={item.path}
                                        activeClass='header_navidation_active'
                                        text={item.name}
                                        customClass='header_navigation_items'
                                    />
                                )
                            })}

                            {/* {!isLogged ? (
                                <ButtonLogin
                                    className='header_navigate_btn_login'
                                    changeColor={activeNavbar}
                                />
                            ) : (
                                <div className='header_navidate_profile'>
                                    <NotificationIcon />
                                    <Link to={DASHBOARD_ROUTER.path}>
                                        <Avatar image={ImageMock} />
                                    </Link>
                                </div>
                            )} */}

                            <div
                                className='header_navigation_menu_mobile'
                                onClick={handleMenuMobile}
                            >
                                <MenuIcon color={activeNavbar ? '' : 'white'} />
                            </div>

                            <div className='header_navigation_mobile' ref={menuMobile}>
                                <div
                                    className='header_navigate_close_menu'
                                    onClick={handleMenuMobile}
                                >
                                    <CloseIcon />
                                </div>
                                {LIST_ROUTER[NAVBAR_ROUTER].map((item) => (
                                    <div onClick={handleMenuMobile} key={item.name}>
                                        <NavigateCustom
                                            url={item.path}
                                            text={item.name}
                                            customClass='header_navigation_mobile_items'
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
