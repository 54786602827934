import * as React from 'react'
import PropTypes from 'prop-types'

const FeaturesIcon = ({ color = '#062828' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95 95'>
        <defs>
            <style>{'.cls-3{fill:#fff}'}</style>
        </defs>
        <g>
            <rect
                width={95}
                height={95}
                rx={11.4}
                style={{
                    fill: color,
                }}
            />
            <g>
                <g>
                    <g>
                        <path
                            style={{
                                fill: 'none',
                            }}
                            d='M17.11 17.11h60.8v60.8h-60.8z'
                        />
                        <path
                            className='cls-3'
                            d='M60.69 35.57h-9.56a1.23 1.23 0 0 1-1.19-1.18 1.19 1.19 0 0 1 .36-.86 1.36 1.36 0 0 1 .83-.36h9.56a1.24 1.24 0 0 1 1.19 1.22 1.23 1.23 0 0 1-1.18 1.18Z'
                        />
                        <path
                            className='cls-3'
                            d='M60.69 40.23h-9.56A1.25 1.25 0 0 1 49.94 39a1.2 1.2 0 0 1 .36-.86 1.36 1.36 0 0 1 .83-.36h9.56A1.24 1.24 0 0 1 61.88 39a1.23 1.23 0 0 1-1.18 1.18Z'
                        />
                        <path
                            className='cls-3'
                            d='M60.69 48.78h-9.56a1.19 1.19 0 0 1-1.19-1.19 1.25 1.25 0 0 1 1.19-1.19h9.56a1.17 1.17 0 0 1 .83.34 1.2 1.2 0 0 1 0 1.68 1.21 1.21 0 0 1-.83.33Z'
                        />
                        <path
                            className='cls-3'
                            d='M60.69 53.43h-9.56a1.17 1.17 0 0 1-.83-.34 1.18 1.18 0 0 1 .83-2h9.56a1.19 1.19 0 0 1 .86 2 1.26 1.26 0 0 1-.83.34Z'
                        />
                        <path
                            className='cls-3'
                            d='M60.69 62h-9.56a1.12 1.12 0 0 1-.83-.36 1.17 1.17 0 0 1 0-1.64 1.08 1.08 0 0 1 .83-.36h9.56a1.13 1.13 0 0 1 .83.36 1.11 1.11 0 0 1 .36.82A1.24 1.24 0 0 1 60.69 62Z'
                        />
                        <path
                            className='cls-3'
                            d='M60.69 66.66h-9.56a1.12 1.12 0 0 1-.83-.36 1.17 1.17 0 0 1 0-1.64 1.12 1.12 0 0 1 .83-.36h9.56a1.13 1.13 0 0 1 .83.36 1.11 1.11 0 0 1 .36.82 1.24 1.24 0 0 1-1.19 1.22Z'
                        />
                        <path
                            className='cls-3'
                            d='M66.58 27.25H53.35a6 6 0 0 0-11.68 0H28.42a1.17 1.17 0 0 0-.83.34 1.15 1.15 0 0 0 0 1.67 1.16 1.16 0 0 0 .82.37h1.22a5.94 5.94 0 0 0-1.22 3.56v33.42a6 6 0 0 0 5.95 5.94h26.28a5.92 5.92 0 0 0 5.94-5.89V33.2a6 6 0 0 0-1.19-3.57h1.19a1.18 1.18 0 0 0 .85-.36 1.08 1.08 0 0 0 .36-.83 1.11 1.11 0 0 0-.36-.85 1.12 1.12 0 0 0-.85-.34Zm-19.07-2.4a3.5 3.5 0 0 1 2.07.67 3.77 3.77 0 0 1 1.32 1.73h-6.75a3.57 3.57 0 0 1 3.36-2.4ZM64.2 33.2v33.41a3.57 3.57 0 0 1-1 2.58 3.67 3.67 0 0 1-2.58 1H34.38a3.67 3.67 0 0 1-2.58-1 3.57 3.57 0 0 1-1-2.58V33.2a3.53 3.53 0 0 1 3.51-3.57h26.33a3.59 3.59 0 0 1 1.37.26 4.26 4.26 0 0 1 1.16.77 4.31 4.31 0 0 1 .77 1.17 3.38 3.38 0 0 1 .26 1.37Z'
                        />
                        <path
                            className='cls-3'
                            d='M39.14 41.49h-2.38a3.61 3.61 0 0 1-3.59-3.59v-2.38A3.6 3.6 0 0 1 36.76 32h2.38a3.56 3.56 0 0 1 3.56 3.56V38a3.55 3.55 0 0 1-3.56 3.49Zm-2.38-7.16a1.28 1.28 0 0 0-.85.26 1.24 1.24 0 0 0-.34.86v2.37a1.24 1.24 0 0 0 .34.86 1.15 1.15 0 0 0 .85.33h2.38a1.17 1.17 0 0 0 .85-.33 1.36 1.36 0 0 0 .34-.86v-2.3a1.14 1.14 0 0 0-1.09-1.19h-2.48Z'
                        />
                        <path
                            className='cls-3'
                            d='M39.14 54.69h-2.38a3.65 3.65 0 0 1-2.58-1 3.56 3.56 0 0 1-1.06-2.58v-2.44a3.57 3.57 0 0 1 3.64-3.61h2.38a3.52 3.52 0 0 1 3.61 3.45v2.56a3.59 3.59 0 0 1-1 2.58 3.73 3.73 0 0 1-2.59 1Zm-2.38-7.18a1.18 1.18 0 0 0-.85.36 1.09 1.09 0 0 0-.34.83v2.4a1.09 1.09 0 0 0 .34.83 1.18 1.18 0 0 0 .85.36h2.38a1.19 1.19 0 0 0 1.19-1.19v-2.4a1.17 1.17 0 0 0-.34-.83 1.27 1.27 0 0 0-.85-.36Z'
                        />
                        <path
                            className='cls-3'
                            d='M39.14 67.9h-2.38a3.56 3.56 0 0 1-3.59-3.54V62a3.61 3.61 0 0 1 3.59-3.59h2.38a3.56 3.56 0 0 1 3.56 3.57v2.4a3.53 3.53 0 0 1-3.51 3.57Zm-2.38-7.16a1.18 1.18 0 0 0-.85.36 1.15 1.15 0 0 0-.34.85v2.38a1.2 1.2 0 0 0 .34.85 1.22 1.22 0 0 0 .85.34h2.38a1.14 1.14 0 0 0 1.19-1.09V62a1.24 1.24 0 0 0-.33-.9 1.18 1.18 0 0 0-.85-.36Z'
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

FeaturesIcon.propTypes = {
    color: PropTypes.string,
}

export default FeaturesIcon
