import { useState } from 'react'
import { API_PUBLIC } from '../service/api'

const POPULATE = 'populate'
const ENDPOINT = '/nosotro'

const useAboutUs = () => {
    const [ dataAboutUs, setDataAboutUs ] = useState([])
    const [ errorDataAboutUs, setErrorDataAboutUs ] = useState('')
    const [ loadingDataAboutUs, setLoadingDataAboutUs ] = useState(true)

    const getDataAboutUs = async () => {
        try {
            setLoadingDataAboutUs(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}?${POPULATE}=*`,
            })

            setDataAboutUs(res.data.attributes)
        } catch (error) {
            setErrorDataAboutUs(error)
        } finally {
            setLoadingDataAboutUs(false)
        }
    }

    return {
        dataAboutUs,
        errorDataAboutUs,
        loadingDataAboutUs,
        getDataAboutUs,
    }
}

export default useAboutUs
