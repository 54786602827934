import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'
import { RenderMarkDown } from '../../../../components/RenderMarkDown'

// Icons
import { ArrowLeftIcon } from '../../../../components/Svg'

const BlogHero = ({ image, title, description, date, goBack }) => {
    return (
        <div className='blogHero'>
            <img src={image} alt='Blog' />
            <div className='blogHero_container container'>
                <div className='content blogHero_content'>
                    <Link
                        className={`blogHero_goBack ${
                            date ? 'blogHero_goBack_mobile' : ''
                        }`}
                        to={goBack}
                    >
                        <ArrowLeftIcon color='white' />
                    </Link>

                    <div className='blogHero_content_text'>
                        <div className='blogHero_title'>
                            <Title size='lg' color='white'>
                                {title}
                            </Title>
                            {date && (
                                <Text classNameCustom='blogHero_date' size='sm' color='white'>
                                    {moment(date).format('DD / MM / YYYY')}
                                </Text>
                            )}
                        </div>
                        <Text classNameCustom='blogHero_text' color='white'>
                            <RenderMarkDown>{description}</RenderMarkDown>
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

BlogHero.propTypes = {
    image: PropTypes.string, 
    date: PropTypes.string,
    title: PropTypes.string, 
    description: PropTypes.string, 
    goBack: PropTypes.string,
}

export default BlogHero
