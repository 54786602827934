import * as React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ color = '#062828' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 70'>
        <path
            fill={color}
            d='M35 28.71A6.29 6.29 0 1 0 41.29 35 6.26 6.26 0 0 0 35 28.71Zm0 10.4A4.11 4.11 0 1 1 39.11 35 4.11 4.11 0 0 1 35 39.11Zm8-10.66A1.49 1.49 0 1 1 41.55 27 1.53 1.53 0 0 1 43 28.45Zm4.19 1.48a7 7 0 0 0-2-5.15 7.27 7.27 0 0 0-5.15-2c-2-.09-8.13-.09-10.14 0a7.19 7.19 0 0 0-5.15 2 7.27 7.27 0 0 0-2 5.15c-.09 2-.09 8.13 0 10.14a7 7 0 0 0 2 5.15 7.27 7.27 0 0 0 5.15 2c2 .09 8.13.09 10.14 0a7 7 0 0 0 5.15-2 7.27 7.27 0 0 0 2-5.15c.09-2 .09-8.13 0-10.14Zm-2.58 12.32a4.12 4.12 0 0 1-2.36 2.36c-1.66.61-5.41.53-7.25.53s-5.59.17-7.25-.53a4.12 4.12 0 0 1-2.36-2.36c-.61-1.66-.53-5.41-.53-7.25s-.17-5.59.53-7.25a4.12 4.12 0 0 1 2.36-2.36c1.66-.61 5.41-.53 7.25-.53s5.59-.17 7.25.53a4.12 4.12 0 0 1 2.36 2.36c.61 1.66.53 5.41.53 7.25s.08 5.59-.53 7.25Z'
        />
    </svg>
)

SvgComponent.propTypes = {
    color: PropTypes.string,
}

export default SvgComponent
