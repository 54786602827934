/* eslint-disable */
// ICONS
import {
    InstagramIcon,
    FacebookIcon,
    WhatsappIcon,
    YoutubeIcon,
    TiktokIcon,
} from '../../components/Svg'

export const SOCIAL_WA = {
    icon: <WhatsappIcon />,
    name: 'Whatsapp',
    url: 'https://api.whatsapp.com/send?phone=573148376156',
}

export const SOCIAL_MEDIA = [
    {
        icon: <InstagramIcon />,
        name: 'Instagram',
        url: 'https://www.instagram.com/elemental_ai/',
    },
    {
        icon: <FacebookIcon />,
        name: 'Facebook',
        url: 'https://www.facebook.com/elemental_ai-125001502266810',
    },
    {
        icon: <YoutubeIcon />,
        name: 'Youtube',
        url: 'https://www.youtube.com/channel/UCCqrbfAQoWAPrYWkfh0oNqw',
    },
    {
        icon: <TiktokIcon />,
        name: 'Tiktok',
        url: 'https://www.tiktok.com/@sebasvelez_realtor',
    },

    SOCIAL_WA,
]
