import React from 'react'
import { Outlet } from 'react-router-dom'

// Components
import { Header } from '../Header'
import { Footer } from '../Footer'

const Layout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default Layout
