import React from 'react'
import PropTypes from 'prop-types'

// Components
import { TitleIcon } from '../../../../components/Title'
import { Text } from '../../../../components/Text'

// Utils
import { TRANS_FIELDS } from '../../../../utils/transFields'
import { IGNORER_BASIC_INFO } from '../../../../utils/ignorerItems'

// Icons
import { InfoIcon } from '../../../../components/Svg'

// Fields
import {
    FIELDS_PROPERTY,
    FIELD_KEY_ADMINISTRATION,
    FIELD_KEY_TOTAL_AREA,
    FIELD_KEY_PRICE,
} from '../../../../utils/fields'

// Helpers
import { formatMoney } from '../../../../utils/helpers'

const SHOW_PRICE = [
    FIELDS_PROPERTY[FIELD_KEY_PRICE],
    FIELDS_PROPERTY[FIELD_KEY_ADMINISTRATION],
]

const SHOW_AREA = [ FIELDS_PROPERTY[FIELD_KEY_TOTAL_AREA] ]

const SHOW_DIFERENT = [ ...SHOW_PRICE, ...SHOW_AREA ]

const BasicInfo = ({ details }) => {
    const showItems = Object.keys(details).filter(
        (field) => !IGNORER_BASIC_INFO.includes(field) && !!details[field],
    )

    const showDetailDiferent = ({ field }) => {
        if (SHOW_PRICE.includes(field))
            return `$${formatMoney({ number: details[field] })}`

        if (SHOW_AREA.includes(field))
            return (
                <>
                    {details[field]}m<sup>2</sup>
                </>
            )

        return ''
    }

    return (
        <div className='basicInfo'>
            <TitleIcon sizeIcon='xl' icon={<InfoIcon />} title='Información basica' />

            <div className='basicInfo_list'>
                {showItems.map((item) => (
                    <div key={item} className='basicInfo_list_item'>
                        <Text bold>{TRANS_FIELDS[item] || item}</Text>
                        <Text color='gray'>
                            {SHOW_DIFERENT.includes(item)
                                ? showDetailDiferent({ field: item })
                                : details[item]}
                        </Text>
                    </div>
                ))}
            </div>
        </div>
    )
}

BasicInfo.propTypes = {
    details: PropTypes.object, 
}

export default BasicInfo
