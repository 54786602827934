import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Components
import { Input, InputGroupRange } from '../../../../components/Input'
import { SelectCount } from '../../../../components/SelectCount'
import { CheckBox } from '../../../../components/Input'
import { Text } from '../../../../components/Text'
import { FilterTitle } from './'

// Icons
import { ArroDownIcon, ArrowUpIcon } from '../../../../components/Svg'

// Utils
import {
    FIELD_KEY_ROOMS,
    FIELD_KEY_BATHROOMS,
    FIELD_KEY_PARKINGLOT,
    FIELD_KEY_LOCATION,
    FIELD_KEY_TYPE,
    FIELD_KEY_PRICE,
    FIELDS_FEATUES,
    FIELD_KEY_FEATURES,
    FIELD_KEY_ADMINISTRATION,
    FIELD_KEY_TOTAL_AREA,
    FIELD_KEY_PROPERTY_CODE,
    FIELDS_PROPERTY,
    FIELD_ORDER_BY,
} from '../../../../utils/fields'
import { TRANS_FIELDS } from '../../../../utils/transFields'

// Const
import {
    MIN_AREA,
    MAX_AREA,
    MIN_ADMINISTRATION,
    MAX_ADMINISTRATION,
} from '../../../../const/inputRange'

const Filter = ({ tablet, data, onChangeFilter, filterFeatureList }) => {
    const [ activeFilter, setActiveFilter ] = useState(tablet ? true : false)
    const [ listCheckValue, setListCheckValue ] = useState([])

    const handleChangeDataFilter = ({ value, field }) => {
        onChangeFilter({ field, value })
    }

    const getCountItemFilter = () => {
        let total = 0
        const filter = data

        Object.keys(filter).forEach((key) => {
            // ignoramos
            if (
                [
                    FIELD_KEY_LOCATION,
                    FIELD_KEY_TYPE,
                    FIELD_KEY_PRICE,
                    FIELD_ORDER_BY,
                ].includes(key)
            )
                return

            if (typeof filter[key] === 'object')
                return (total += filter[key].min !== null ? 1 : 0)

            total += filter[key] ? 1 : 0
        })

        return total
    }

    const handleChangeCheckBox = ({ field, value }) => {
        let list = Array.from(listCheckValue)

        // Agregamos la caracteristica
        if (value && !listCheckValue.includes(field)) {
            list.push(field)
        } else {
            list = list.filter((item) => item !== field)
        }

        setListCheckValue(list)
        onChangeFilter &&
            onChangeFilter({ field: FIELD_KEY_FEATURES, value: list })
    }

    const totalFilter = getCountItemFilter()

    return (
        <div className='filterProperties'>
            {!tablet && (
                <div
                    className='filterProperties_title'
                    onClick={() => setActiveFilter(!activeFilter)}
                >
                    <FilterTitle />

                    <div className='filterProperties_titleItem_arrow'>
                        {totalFilter > 0 && (
                            <Text classNameCustom='filterProperties_total'>
                                {totalFilter}
                            </Text>
                        )}
                        {activeFilter ? <ArrowUpIcon /> : <ArroDownIcon />}
                    </div>
                </div>
            )}

            {activeFilter && (
                <div className='filterProperties_content'>
                    <Input
                        label='Código de propiedad'
                        labelBold
                        placeholder='Código de propiedad'
                        name={FIELD_KEY_PROPERTY_CODE}
                        value={data[FIELD_KEY_PROPERTY_CODE] || ''}
                        onChange={(e) =>
                            handleChangeDataFilter({
                                value: e.target.value,
                                field: e.target.name,
                            })
                        }
                    />
                    <SelectCount
                        text='Habitaciones'
                        number={data[FIELD_KEY_ROOMS] || ''}
                        onClick={({ value }) =>
                            handleChangeDataFilter({ value, field: FIELD_KEY_ROOMS })
                        }
                    />
                    <SelectCount
                        text='Baños'
                        number={data[FIELD_KEY_BATHROOMS] || ''}
                        onClick={({ value }) =>
                            handleChangeDataFilter({ value, field: FIELD_KEY_BATHROOMS })
                        }
                    />
                    <SelectCount
                        text='Parqueadero'
                        number={data[FIELD_KEY_PARKINGLOT] || ''}
                        onClick={({ value }) =>
                            handleChangeDataFilter({ value, field: FIELD_KEY_PARKINGLOT })
                        }
                    />

                    <Text bold classNameCustom='filterProperties_checkLabel'>
                        Preferiblemente con
                    </Text>
                    <div className='filterProperties_checkBoxs'>
                        {filterFeatureList.length > 0 &&
                            filterFeatureList.map((item) => (
                                <CheckBox
                                    key={item.attributes[FIELDS_FEATUES.name]}
                                    label={item.attributes[FIELDS_FEATUES.name]}
                                    name={item.attributes[FIELDS_FEATUES.name]}
                                    onChange={(e) =>
                                        handleChangeCheckBox({
                                            value: e.target.checked,
                                            field: e.target.name,
                                        })
                                    }
                                />
                            ))}
                    </div>

                    <InputGroupRange
                        label={TRANS_FIELDS[FIELDS_PROPERTY[FIELD_KEY_TOTAL_AREA]]}
                        field={FIELD_KEY_TOTAL_AREA}
                        type={FIELD_KEY_TOTAL_AREA}
                        min={MIN_AREA}
                        max={MAX_AREA}
                        data={data}
                        onChange={handleChangeDataFilter}
                    />

                    <InputGroupRange
                        label={TRANS_FIELDS[FIELDS_PROPERTY[FIELD_KEY_ADMINISTRATION]]}
                        field={FIELD_KEY_ADMINISTRATION}
                        type={FIELD_KEY_PRICE}
                        min={MIN_ADMINISTRATION}
                        max={MAX_ADMINISTRATION}
                        data={data}
                        onChange={handleChangeDataFilter}
                    />
                </div>
            )}
        </div>
    )
}

Filter.propTypes = {
    listPlaces: PropTypes.array, 
    tablet: PropTypes.bool, 
    data: PropTypes.object ,
    onChangeFilter: PropTypes.func, 
    filterFeatureList: PropTypes.array,
}

export default Filter
