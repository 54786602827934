import React from 'react'
import PropTypes from 'prop-types'

// Components
import { InputRange, Input } from './'

// Helpers
import { formatMoney, removeDot } from '../../utils/helpers'

// Const
import { FIELD_KEY_PRICE } from '../../utils/fields'

const InputGroupRange = ({ onChange, label, min, max, type, field, data }) => {
    const handleChange = ({ minV, maxV }) => {
        if (min === minV && max === maxV)
            return onChange({
                field,
                value: { min: null, max: null },
            })

        onChange({
            field,
            value: { min: minV, max: maxV },
        })
    }

    return (
        <>
            <InputRange
                label={label}
                type={type}
                min={min}
                max={max}
                onChange={handleChange}
            />
            <div className='filterProperties_checkBoxs'>
                <Input
                    label='Desde'
                    placeholder='Desde'
                    name={field}
                    value={`${type === FIELD_KEY_PRICE ? '$' : ''} ${formatMoney({
                        number: data[field]?.min ?? min,
                    })}`}
                    onChange={(e) =>
                        handleChange({
                            minV: Number(removeDot({ string: e.target.value })),
                            maxV: data[field]?.max || max,
                        })
                    }
                />
                <Input
                    label='Hasta'
                    placeholder='Hasta'
                    name={field}
                    value={`${type === FIELD_KEY_PRICE ? '$' : ''} ${formatMoney({
                        number: data[field]?.max ?? max,
                    })}`}
                    onChange={(e) =>
                        handleChange({
                            minV: data[field]?.min || min,
                            maxV: Number(removeDot({ string: e.target.value })),
                        })
                    }
                />
            </div>
        </>
    )
}

InputGroupRange.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    type: PropTypes.string,
    field: PropTypes.string,
    data: PropTypes.object,
}

export default InputGroupRange
