import { useState } from 'react'
import { API_PUBLIC } from '../service/api'

const ENDPOINT = '/terminos-condicion'

const useTermsAndConditions = () => {
    const [ dataTermsAndConditions, setDataTermsAndConditions ] = useState([])
    const [ errorDataTermsAndConditions, setErrorDataTermsAndConditions ] = useState('')
    const [ loadingDataTermsAndConditions, setLoadingDataTermsAndConditions ] = useState(true)

    const getDataTermsAndConditions = async () => {
        try {
            setLoadingDataTermsAndConditions(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}`,
            })

            setDataTermsAndConditions(res.data.attributes)
        } catch (error) {
            setErrorDataTermsAndConditions(error)
        } finally {
            setLoadingDataTermsAndConditions(false)
        }
    }

    return {
        dataTermsAndConditions,
        errorDataTermsAndConditions,
        loadingDataTermsAndConditions,
        getDataTermsAndConditions,
    }
}

export default useTermsAndConditions
