import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'

// Router
import { PLACES_OF_INTEREST_ROUTER } from '../../helpers/constants/routes'

// Icons
import { LocationIcon, ArrowRightIcon } from '../Svg'

const PlacesAndSectorsCard = ({ location, image, id }) => {
    return (
        <Link
            to={`${PLACES_OF_INTEREST_ROUTER.path}/${id}`}
            className='placesAndSectors_list_item'
        >
            <img src={image} alt={location} />
            <div className='placesAndSectors_location'>
                <Text classNameCustom='placesAndSectors_location_first'>
                    <LocationIcon /> {location}
                </Text>
                <ArrowRightIcon />
            </div>
        </Link>
    )
}

PlacesAndSectorsCard.propTypes = {
    location: PropTypes.string,
    image: PropTypes.string,
    id: PropTypes.number,
}

export default PlacesAndSectorsCard
