import * as React from 'react'
import PropTypes from 'prop-types'

const LocationFullIcon = ({ color = '#30a68f' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 34'>
        <g>
            <path fill='none' d='M0 0h34v34H0z' />
            <path
                d='M24.37 8.686a8.565 8.565 0 0 0-15.884 5.452 12.091 12.091 0 0 0 .93 2.7l.529 1.073 5.295 10.532a1.817 1.817 0 0 0 .715.787 1.917 1.917 0 0 0 1.03.3 2.132 2.132 0 0 0 1.059-.286 2.075 2.075 0 0 0 .73-.8l5.28-10.56c.129-.243.286-.572.472-.973a15.468 15.468 0 0 0 1.059-2.776 8.385 8.385 0 0 0-1.216-5.452Zm-7.369 3.191a1.96 1.96 0 0 1 1.1.329 1.917 1.917 0 0 1 .73.887 1.975 1.975 0 0 1-.429 2.161 1.875 1.875 0 0 1-1 .544 2.018 2.018 0 0 1-1.159-.114 1.971 1.971 0 0 1 .758-3.806Z'
                fill={color}
            />
        </g>
    </svg>
)

LocationFullIcon.propTypes = {
    color: PropTypes.string,
}

export default LocationFullIcon
